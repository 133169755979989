// src/GlobalState.js
import React, { createContext, useState, useEffect, useRef } from "react";

export const GlobalStateContext = createContext();

const getInitialRateLimitCount = () => {
  const savedCount = localStorage.getItem("RATE_LIMIT_COUNT");
  return savedCount !== null ? parseInt(savedCount, 10) : 10; // default value 10
};

const getInitialTimerValue = () => {
  const savedTimer = localStorage.getItem("TIMER");
  return savedTimer !== null ? parseInt(savedTimer, 10) : 59; // default timer value 59
};

export const GlobalStateProvider = ({ children }) => {
  const [rateLimitCount, setRateLimitCount] = useState(
    getInitialRateLimitCount
  );
  const [timer, setTimer] = useState(getInitialTimerValue);

  const intervalRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("RATE_LIMIT_COUNT", rateLimitCount);
  }, [rateLimitCount]);

  useEffect(() => {
    localStorage.setItem("TIMER", timer);
  }, [timer]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          setRateLimitCount(10); // Reset rate limit count to default value
          return 59; // Reset timer to 59
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <GlobalStateContext.Provider
      value={{ rateLimitCount, setRateLimitCount, timer }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
