import React from "react";
import "./Loader.css"; // Create a separate CSS file for loader styles
import loaderGif from "../assets/loader.gif"; // Adjust the path to your GIF

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={loaderGif} alt="Loading..." className="loader-gif" />
      {/* <p>
        <b>Please wait while we calculate your personalised result...</b>
      </p> */}
    </div>
  );
};

export default Loader;
