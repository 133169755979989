const exampleTextsLoShuGrid = {
  step1:
    "Write Down Your Date of Birth and (optional) Name: Example: 23-05-1978",
  step2:
    "Calculate Driver Number: Sum of DD from your Date of Birth: 23 → 2 + 3 = 5",
  step3:
    "Calculate Conductor Number: Sum of all digits of your date of birth together until you get a single digit: 2 + 3 + 0 + 5 + 1 + 9 + 7 + 8 = 35 → 3 + 5 = 8. Reference Lo Shu Grid for more details.",
  step4:
    "(Optional) Calculate Your Name Number: Sum of all alphabets of your name reduced to a single digit using the Chaldean Chart",
  step5:
    "Place Individual Digits in Lo Shu Grid. Numbers missing from the range 1 to 9 will not appear in the grid.",
  step6:
    "Compile and Interpret the Lo Shu Grid: Based on the fixed positions and missing numbers, interpret the grid to understand the influence of the numbers on your profile.",
};

export default exampleTextsLoShuGrid;
