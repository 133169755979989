const exampleTextsHiddenPassion = {
  step1: "Write Down Your Full Name: Ashish Batra.",
  step2: "Use the Pythagorean Numerology Chart to map each letter to a digit.",
  step3:
    "Assign Corresponding Digits to Each Letter: A = 1, S = 1, H = 8, I = 9, S = 1, H = 8, B = 2, A = 1, T = 2, R = 9, A = 1.",
  step4:
    "Count the Occurrences of Each Digit: 1 appears 5 times, 2 appears 2 times, 8 appears 2 times, 9 appears 2 times.",
  step5: "Identify the Digit Present Most Frequently: 1.",
  step6:
    "Determine Your Hidden Passion Number: The Hidden Passion Number is 1.",
};

export default exampleTextsHiddenPassion;
