const exampleTextsKuaCompatibility = {
  step1: "Partner 1 (Male) - Birthdate: 01/01/1989",
  step2:
    "Sum and reduce the last two digits of your birth year to a single digit. Last two digits of 1989: 8 + 9 = 17 → 1 + 7 = 8.",
  step3:
    "If born before 2000, subtract this single digit from 10. 10 - 8 = 2. Kua Number for Partner 1 (Male) is 2.",

  step4: "Partner 2 (Female) - Birthdate: 01/05/1991",
  step5:
    "Sum and reduce the last two digits of your birth year to a single digit. Last two digits of 1991: 9 + 1 = 10 → 1 + 0 = 1. Add 5 to this single digit and reduce again to a single digit. 1 + 5 = 6.",
  step6:
    "Kua Number for Partner 2 (Female) is 6. Find compatibility between Kua Numbers 2 and 2 using the provided compatibility table.",
};

export default exampleTextsKuaCompatibility;
