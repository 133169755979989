const exampleTextsDestiny = {
  step1: "Write Down the Full Name: Example: Ashish Batra",
  step2:
    "Use the Chaldean Numerological Chart to Find Corresponding Digits: A = 1, S = 3, H = 5, I = 1, B = 2, T = 4, R = 2",
  step3:
    "Assign Corresponding Digits to Each Letter: Ashish: A(1), S(3), H(5), I(1), S(3), H(5); Batra: B(2), A(1), T(4), R(2), A(1)",
  step4:
    "Add All Assigned Digits Together: Ashish: 1 + 3 + 5 + 1 + 3 + 5 = 18; Batra: 2 + 1 + 4 + 2 + 1 = 10; Total Sum: 18 + 10 = 28",
  step5: "Reduce the Total Sum to a Single Digit: 28 → 2 + 8 = 10 → 1 + 0 = 1",
  step6:
    "Determine the Chaldean Destiny Number: The Chaldean Destiny Number for Ashish Batra is 1.",
};

export default exampleTextsDestiny;
