export const bankNumberInstructions = {
  howToCalculate: `
    <p>Write down your complete bank account number.</p>

    <p>Add all digits together to make a single number unless it is a Master Number (11, 22, 33).</p>

    <p>The resulting number is your lucky bank account number on which we provide interpretation.</p>

    <p>As we understand, every number comes with positives and negatives, and if we know about those positives and negatives, we can make more calculated decisions. Sometimes changing the bank account to a desired number is not possible.</p>
  `,
};
