export const kuaCompatibilityInstructions = {
  howToCalculate: `
    <p>Find out the Kua number for both partners.</p>
    <p><b>For Males:</b><br>
    Sum and reduce the last two digits of your birth year to a single digit.<br>
    If born before 2000, subtract this single digit from 10.<br>
    If born in or after 2000, add 9 to the single digit, then subtract from 10.<br>
    The result is your Kua number.</p>
    <p><b>For Females:</b><br>
    Sum and reduce the last two digits of your birth year to a single digit.<br>
    Add 5 to this single digit and reduce again to a single digit.<br>
    If born in or after 2000, subtract 4 from the single digit.<br>
    The result is your Kua number.</p>
    <p><b>Important Note:</b> For Kua Number 5, if your calculation results in 5, use Kua number 2 for males and Kua number 8 for females.</p>
    <p>Once the Kua number is retrieved for both partners, find the compatibility using the table below.</p>
  `,
};
