export const dailyBiorhythmInstructions = {
  howToCalculate: `
    <p>Write down today's date and your date of birth in DD-MM-YYYY format.</p>

    <p>Find the number of days between your date of birth and today's date.</p>

    <p>Put the number of days in the formula below to get the value of emotional, physical, and intellectual value:</p>

  `,
};
