export const personalYearInstructions = {
  howToCalculate: (
    <div>
      <p>
        Write down your date of birth in DD-MM format.
        <br />
        Add all digits together, i.e., D + D + M + M + current year in full (Y +
        Y + Y + Y).
        <br />
        If the sum is greater than 9, then sum both digits again until the sum
        is a single digit.
        <br />
        At any time during the summation, if the result is 11, 22, or 33, stop
        as these are considered Master Numbers.
      </p>
    </div>
  ),
};
