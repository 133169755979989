import React, { useState, useEffect, useContext } from "react";
import "./LoShuGrid.css";
import { GlobalStateContext } from "../components/GlobalVariable";
import { loShuGridInstructions } from "./LoShuGrid_How_to";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import {
  LO_SHU_FAQ_QUESTION_1,
  LO_SHU_FAQ_ANSWER_1,
  LO_SHU_FAQ_QUESTION_2,
  LO_SHU_FAQ_ANSWER_2,
  LO_SHU_FAQ_QUESTION_3,
  LO_SHU_FAQ_ANSWER_3,
  LO_SHU_FAQ_QUESTION_4,
  LO_SHU_FAQ_ANSWER_4,
  LO_SHU_FAQ_QUESTION_5,
  LO_SHU_FAQ_ANSWER_5,
  LO_SHU_FAQ_QUESTION_6,
  LO_SHU_FAQ_ANSWER_6,
  LO_SHU_FAQ_QUESTION_7,
  LO_SHU_FAQ_ANSWER_7,
  LO_SHU_FAQ_QUESTION_8,
  LO_SHU_FAQ_ANSWER_8,
  LO_SHU_FAQ_QUESTION_9,
  LO_SHU_FAQ_ANSWER_9,
  LO_SHU_FAQ_QUESTION_10,
  LO_SHU_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsLoShuGrid from "./LoShuGrid_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: LO_SHU_FAQ_QUESTION_1, answer: LO_SHU_FAQ_ANSWER_1 },
    { question: LO_SHU_FAQ_QUESTION_2, answer: LO_SHU_FAQ_ANSWER_2 },
    { question: LO_SHU_FAQ_QUESTION_3, answer: LO_SHU_FAQ_ANSWER_3 },
    { question: LO_SHU_FAQ_QUESTION_4, answer: LO_SHU_FAQ_ANSWER_4 },
    { question: LO_SHU_FAQ_QUESTION_5, answer: LO_SHU_FAQ_ANSWER_5 },
    { question: LO_SHU_FAQ_QUESTION_6, answer: LO_SHU_FAQ_ANSWER_6 },
    { question: LO_SHU_FAQ_QUESTION_7, answer: LO_SHU_FAQ_ANSWER_7 },
    { question: LO_SHU_FAQ_QUESTION_8, answer: LO_SHU_FAQ_ANSWER_8 },
    { question: LO_SHU_FAQ_QUESTION_9, answer: LO_SHU_FAQ_ANSWER_9 },
    { question: LO_SHU_FAQ_QUESTION_10, answer: LO_SHU_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function LoShuGrid({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const url = window.location.href;
  const mouseTitle = "Create your Lo Shu Grid";
  const browserTitle = "Lo Shu Grid";
  const [isTimerActive, setIsTimerActive] = useState(false); // New state to track if the timer is active
  const [fullName, setFullName] = useState(userData?.fullName || "");
  const [day, setDay] = useState(userData?.day || 1);
  const [month, setMonth] = useState(userData?.month || 1);
  const [, setError] = useState(null);
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const [year, setYear] = useState(userData?.year || 1900);
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [apiResult, setApiResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null); // Define startTime state
  const [loaderFinished, setLoaderFinished] = useState(false); // New state variable to track loader completion
  const shareText =
    "Quick and easy way to make the perfect Lo sho grid , Create your own and also find missing number remedies at";

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
    setApiResult(null); // Clear previous API result
  };

  const hasContent = apiResult !== null;

  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName || "");
      setDay(userData.day || 1);
      setMonth(userData.month || 1);
      setYear(userData.year || 1900);
    }
  }, [userData]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setApiResult(null); // Clear previous API result
  };

  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setApiResult(null); // Clear previous API result
  };

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setApiResult(null); // Clear previous API result
  };

  // Loader completion handler
  const handleLoaderCompletion = () => {
    setLoaderFinished(true);
  };

  // Finally block to handle loading state
  // Add the following useEffect to handle the loader timing and API response display
  useEffect(() => {
    if (startTime !== null) {
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(5000 - elapsedTime, 0); // Ensure at least 5 seconds

      const timer = setTimeout(() => {
        setLoading(false);
        setLoaderFinished(true); // Mark loader as finished after 5 seconds
      }, remainingTime);

      return () => clearTimeout(timer); // Clear timeout on component unmount
    }
  }, [startTime]);

  useEffect(() => {
    if (startTime !== null && !isTimerActive) {
      setIsTimerActive(true);
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(5000 - elapsedTime, 0); // Ensure at least 5 seconds

      const timer = setTimeout(() => {
        setLoading(false);
        setLoaderFinished(true); // Mark loader as finished after 5 seconds
        setIsTimerActive(false); // Reset timer active state
      }, remainingTime);

      return () => clearTimeout(timer); // Clear timeout on component unmount
    }
  }, [startTime, isTimerActive]);
  // Modify the handleSubmit function to initiate loader and API call timing
  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }

    setLoading(true);
    setLoaderFinished(false); // Reset loader finished state
    setStartTime(Date.now()); // Record the start time for loader timing
    setClicksRemaining((prev) => prev - 1);

    // Start timer if not already active
    if (!isTimerActive) {
      startTimer();
    }

    setUserData({
      ...userData,
      fullName: fullName,
      day: day,
      month: month,
      year: year,
    });

    // Make API call here
    const formData = {
      dateOfBirth: `${day}-${month}-${year}`,
      name: fullName,
    };

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/loshogrid",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      setApiResult(data);
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };

  // API result rendering logic
  // API result rendering logic
  const renderApiResult = () => {
    if (apiResult && loaderFinished) {
      const {
        loshoGrid,
        missingLoshogridNumber,
        possible_Issues,
        remedies,
        lucky_Name,
        lucky_Name_Numbers_Suggested,
        harmonizingPlanet,
        nameNumber,
        lifePath,
        lifePath_planet,
        main_Driver_psyhic_moolank_planet,
        mainPlanet,
        thoughtPlane,
        willPlane,
        actionPlane,
        goldenYog,
        silverYog,
        mentalPlane,
        emotionalPlane,
        practicalPlane,
        ...otherApiResults
      } = apiResult; // Destructure to exclude loshoGrid

      return (
        <div
          className={`left-align-content result-ui ${
            hasContent ? "filled" : ""
          }`}
          id="breadcrumbs"
        >
          {/* Display loshoGrid */}
          {loshoGrid && (
            <div className="loshu-grid">
              {loshoGrid.flat().map((cell, index) => (
                <div key={index} className="grid-cell">
                  {cell}
                </div>
              ))}
            </div>
          )}

          {/* Display missingLoshogridNumber */}
          {missingLoshogridNumber && (
            <div className="missing-numbers">
              <h3>Missing Digits:</h3>
              <p>
                {missingLoshogridNumber.map((num) => (
                  <span key={num} className="highlight">
                    {num}
                  </span>
                ))}
              </p>
            </div>
          )}

          {/* Display possible_Issues */}
          {possible_Issues && (
            <div className="possible-issues">
              <h3>Possible Issues:</h3>
              <ul>
                {possible_Issues.map((issue, index) => (
                  <li key={index} className="issue-item">
                    {issue}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Display remedies */}
          {remedies && (
            <div className="remedies">
              <h3>Remedies:</h3>
              <ul>
                {remedies.map((remedy, index) => (
                  <li key={index} className="remedy-item">
                    {remedy}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Display lucky_Name */}
          <div className="lucky-name">
            <p>
              <b>Lucky Name:</b>
              {lucky_Name ? "Yes" : "No"}
            </p>
          </div>

          {/* Display lucky_Name_Numbers_Suggested */}
          {lucky_Name_Numbers_Suggested && (
            <div className="lucky-name-numbers">
              <p>
                <b>Lucky Name Numbers Suggested:</b>
                {lucky_Name_Numbers_Suggested.map((num, index) => (
                  <span key={index} className="highlight">
                    {num}
                  </span>
                ))}
              </p>
            </div>
          )}

          {/* Display harmonizingPlanet */}
          <div className="harmonizing-planet">
            <p>
              <b>Harmonizing Planet:</b>
              {harmonizingPlanet}
            </p>
          </div>

          {/* Display nameNumber */}
          <div className="name-number">
            <p>
              <b>Name Number:</b>
              {nameNumber}
            </p>
          </div>

          {/* Display lifePath */}
          <div className="life-path">
            <p>
              <b>Life Path:</b>
              {lifePath}
            </p>
          </div>

          {/* Display lifePath_planet */}
          <div className="life-path-planet">
            <p>
              <b>Life Path Planet:</b>
              {lifePath_planet}
            </p>
          </div>

          {/* Display main_Driver_psyhic_moolank_planet */}
          <div className="main-driver-psychic-moolank-planet">
            <p>
              <b>Main Driver Psychic Moolank Planet:</b>
              {main_Driver_psyhic_moolank_planet}
            </p>
          </div>

          {/* Display mainPlanet */}
          <div className="main-planet">
            <p>
              <b>Main Planet:</b>
              {mainPlanet}
            </p>
          </div>

          {/* Display thoughtPlane */}
          <div className="thought-plane">
            <p>
              <b>Thought Plane:</b>
              {thoughtPlane ? "Yes" : "No"}
            </p>
          </div>

          {/* Display willPlane */}
          <div className="will-plane">
            <p>
              <b>Will Plane:</b>
              {willPlane ? "Yes" : "No"}
            </p>
          </div>

          {/* Display actionPlane */}
          <div className="action-plane">
            <p>
              <b>Action Plane:</b>
              {actionPlane ? "Yes" : "No"}
            </p>
          </div>

          {/* Display goldenYog */}
          <div className="golden-yog">
            <p>
              <b>Golden Yog:</b>
              {goldenYog ? "Yes" : "No"}
            </p>
          </div>

          {/* Display silverYog */}
          <div className="silver-yog">
            <p>
              <b>Silver Yog:</b>
              {silverYog ? "Yes" : "No"}
            </p>
          </div>

          {/* Display mentalPlane */}
          <div className="mental-plane">
            <p>
              <b>Mental Plane:</b>
              {mentalPlane ? "Yes" : "No"}
            </p>
          </div>

          {/* Display emotionalPlane */}
          <div className="emotional-plane">
            <p>
              <b>Emotional Plane:</b>
              {emotionalPlane ? "Yes" : "No"}
            </p>
          </div>

          {/* Display practicalPlane */}
          <div className="practical-plane">
            <p>
              <b>Practical Plane:</b>
              {practicalPlane ? "Yes" : "No"}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="LoShuGrid">
      <Helmet>
        <title>Lo Shu Grid</title>
        <meta
          name="description"
          content="Explore the Lo Shu Grid to analyze your personality traits and life patterns based on the ancient Chinese numerology square."
        />
      </Helmet>
      <div className="content">
        <br />
        <div className="special">
          <h1>Lo Shu Grid Calculator</h1>
          <div>
            <p className="label">Enter your Full Name:</p>
            <input
              type="text"
              value={fullName}
              onChange={handleFullNameChange}
              placeholder="Type here..."
            />
          </div>
          <div>
            <DateOfBirthSelector
              day={day}
              month={month}
              year={year}
              daysInMonth={daysInMonth}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
            />
          </div>
          <button
            onClick={handleSubmit}
            disabled={loading || clicksRemaining <= 0}
          >
            Submit
          </button>
        </div>
        <div></div>

        {apiResult && renderApiResult()}
        {/* Loader component */}
        {loading && <Loader onFinish={handleLoaderCompletion} />}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate Lo Shu Grid</h2>
          {loShuGridInstructions.howToCalculate}
        </div>
        <ExampleUI texts={exampleTextsLoShuGrid} />
        <ShareButtons url={url} title="Discover Lo Shu Grid" />
        <div className="left-align-content" id="breadcrumbs">
          <Faq />
        </div>
      </div>
      <MouseFollower mouseTitle={mouseTitle} />
    </div>
  );
}

export default LoShuGrid;
