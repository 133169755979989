export const passionInstructions = {
  howToCalculate: `
    <p>Write down your full name exactly as it appears.</p>

    <p>Use the Pythagorean numerology chart to find the corresponding digits for each letter in your full name.</p>

    <p>Assign the corresponding digit to each letter according to the numeral chart.</p>

    <p>Identify the digit present in your name the most number of times.</p>

    <p>The digit that appears the most frequently in your name is your Hidden Passion Number.</p>
  `,
};
