const exampleTextsFastingDay = {
  step1: "Write Down Your Date of Birth. Example: 15-07-1985",
  step2:
    "Calculate Driver Number: Sum of DD from your Date of Birth: 15 → 1 + 5 = 6",
  step3:
    "Calculate Conductor Number: Sum of all digits of your date of birth together until you get a single digit: 1 + 5 + 0 + 7 + 1 + 9 + 8 + 5 = 36 → 3 + 6 = 9",
  step4:
    "Find the Planet Information: Based on Driver Number (6) and Conductor Number (9), look up the corresponding planets from the chart: Driver Number Planet: Venus, Conductor Number Planet: Mars",
  step5:
    "Find the Harmonizing Planet: Use the algorithm to find the harmonizing planet between Venus and Mars: Harmonizing Planet: Mercury",
  step6:
    "Map the Harmonizing Planet to the Day: Map the harmonizing planet with the corresponding day names from the chart: Day Name: Friday",
};

export default exampleTextsFastingDay;
