import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  FAQ_HEADER,
  GEN_FAQ_QUESTION_1,
  GEN_FAQ_ANSWER_1,
  GEN_FAQ_QUESTION_2,
  GEN_FAQ_ANSWER_2,
  GEN_FAQ_QUESTION_3,
  GEN_FAQ_ANSWER_3,
  GEN_FAQ_QUESTION_4,
  GEN_FAQ_ANSWER_4,
  GEN_FAQ_QUESTION_5,
  GEN_FAQ_ANSWER_5,
  GEN_FAQ_QUESTION_6,
  GEN_FAQ_ANSWER_6,
  GEN_FAQ_QUESTION_7,
  GEN_FAQ_ANSWER_7,
  GEN_FAQ_QUESTION_8,
  GEN_FAQ_ANSWER_8,
  GEN_FAQ_QUESTION_9,
  GEN_FAQ_ANSWER_9,
  GEN_FAQ_QUESTION_10,
  GEN_FAQ_ANSWER_10,
  GEN_FAQ_QUESTION_11,
  GEN_FAQ_ANSWER_11,
  GEN_FAQ_QUESTION_12,
  GEN_FAQ_ANSWER_12,
  GEN_FAQ_QUESTION_13,
  GEN_FAQ_ANSWER_13,
  GEN_FAQ_QUESTION_14,
  GEN_FAQ_ANSWER_14,
  GEN_FAQ_QUESTION_15,
  GEN_FAQ_ANSWER_15,
  GEN_FAQ_QUESTION_16,
  GEN_FAQ_ANSWER_16,
  GEN_FAQ_QUESTION_17,
  GEN_FAQ_ANSWER_17,
  GEN_FAQ_QUESTION_18,
  GEN_FAQ_ANSWER_18,
  GEN_FAQ_QUESTION_19,
  GEN_FAQ_ANSWER_19,
  GEN_FAQ_QUESTION_20,
  GEN_FAQ_ANSWER_20,
  GEN_FAQ_QUESTION_21,
  GEN_FAQ_ANSWER_21,
  GEN_FAQ_QUESTION_22,
  GEN_FAQ_ANSWER_22,
  GEN_FAQ_QUESTION_23,
  GEN_FAQ_ANSWER_23,
  GEN_FAQ_QUESTION_24,
  GEN_FAQ_ANSWER_24,
} from "../components/Constants";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: GEN_FAQ_QUESTION_1, answer: GEN_FAQ_ANSWER_1 },
    { question: GEN_FAQ_QUESTION_2, answer: GEN_FAQ_ANSWER_2 },
    { question: GEN_FAQ_QUESTION_3, answer: GEN_FAQ_ANSWER_3 },
    { question: GEN_FAQ_QUESTION_4, answer: GEN_FAQ_ANSWER_4 },
    { question: GEN_FAQ_QUESTION_5, answer: GEN_FAQ_ANSWER_5 },
    { question: GEN_FAQ_QUESTION_6, answer: GEN_FAQ_ANSWER_6 },
    { question: GEN_FAQ_QUESTION_7, answer: GEN_FAQ_ANSWER_7 },
    { question: GEN_FAQ_QUESTION_8, answer: GEN_FAQ_ANSWER_8 },
    { question: GEN_FAQ_QUESTION_9, answer: GEN_FAQ_ANSWER_9 },
    { question: GEN_FAQ_QUESTION_10, answer: GEN_FAQ_ANSWER_10 },
    { question: GEN_FAQ_QUESTION_11, answer: GEN_FAQ_ANSWER_11 },
    { question: GEN_FAQ_QUESTION_12, answer: GEN_FAQ_ANSWER_12 },
    { question: GEN_FAQ_QUESTION_13, answer: GEN_FAQ_ANSWER_13 },
    { question: GEN_FAQ_QUESTION_14, answer: GEN_FAQ_ANSWER_14 },
    { question: GEN_FAQ_QUESTION_15, answer: GEN_FAQ_ANSWER_15 },
    { question: GEN_FAQ_QUESTION_16, answer: GEN_FAQ_ANSWER_16 },
    { question: GEN_FAQ_QUESTION_17, answer: GEN_FAQ_ANSWER_17 },
    { question: GEN_FAQ_QUESTION_18, answer: GEN_FAQ_ANSWER_18 },
    { question: GEN_FAQ_QUESTION_19, answer: GEN_FAQ_ANSWER_19 },
    { question: GEN_FAQ_QUESTION_20, answer: GEN_FAQ_ANSWER_20 },
    { question: GEN_FAQ_QUESTION_21, answer: GEN_FAQ_ANSWER_21 },
    { question: GEN_FAQ_QUESTION_22, answer: GEN_FAQ_ANSWER_22 },
    { question: GEN_FAQ_QUESTION_23, answer: GEN_FAQ_ANSWER_23 },
    { question: GEN_FAQ_QUESTION_24, answer: GEN_FAQ_ANSWER_24 },
  ];

  const faqs = rawFaqs.filter(faq => faq.question);

  return (
    <div>
      <Helmet>
        <title>Elite Numerology FAQs</title>
        <meta
          name="description"
          content="These are the most commonly asked questions about Elite Numerology, the services we provide, and numerology in general"
          title="Elite Numerology FAQs"
        />
      </Helmet>
      <h1 className="gen-faq-header">{FAQ_HEADER}</h1>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
              {faq.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer" onClick={() => toggleFAQ(index)}>
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

<div className="faq-section">
  <h2 className="faq-heading">Frequently Asked Questions</h2>
  <Faq />
</div>;

export default Faq;
