export const maturityInstructions = {
  howToCalculate: `
    <p>Write down your full date of birth in the format DD-MM-YYYY and your full name.</p>

    <p>Calculate your Name Number (for details on how to calculate you can click <a class= "midpagelink" href="/name-number-calculator">here</a>).</p>

    <p>Calculate your Life Path Number (for details on how to calculate you can click <a class="midpagelink" href="/life-path-number-calculator">here</a>).</p>

    <p>Reduce both numbers to a single digit, even if they are master numbers.</p>

    <p>Sum both numbers together and reduce them to a single number unless it is a master number (11, 22, or 33).</p>
  `,
};
