// src/constants/exampleTextsBankAccount.js
const exampleTextsBankAccount = {
  step1: "Write Down Your Complete Bank Account Number: Example: 123456789",
  step2:
    "Add All Digits Together: Add all digits of the bank account number: 1 + 2 + 3 + 4 + 5 + 6 + 7 + 8 + 9 = 45",
  step3:
    "Reduce the Sum to a Single Number: Reduce the total sum: 4 + 5 = 9; The resulting number is 9, which is not a master number and does not require further reduction.",
  step4:
    "Interpret the Lucky Bank Account Number: The resulting single digit or master number is 9.",
  step5:
    "Number 9 qualities are : Humanitarianism and Generosity, Patience and Tolerance, Global Awareness.",
  step6:
    "Consider Changes Wisely: Changing your bank account number to a desired number may not always be possible. Understanding your current number's traits can help in making calculated decisions.",
};

export default exampleTextsBankAccount;
