import React, { useState, useEffect } from "react";
import "./MouseFollower.css"; // Adjust the path as needed

const MouseFollower = ({ mouseTitle }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOverHeaderOrFooter, setIsOverHeaderOrFooter] = useState(false);

  useEffect(() => {
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");

    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });

      if (header && footer) {
        const headerRect = header.getBoundingClientRect();
        const footerRect = footer.getBoundingClientRect();
        const isOverHeader =
          event.clientY >= headerRect.top && event.clientY <= headerRect.bottom;
        const isOverFooter =
          event.clientY >= footerRect.top && event.clientY <= footerRect.bottom;

        setIsOverHeaderOrFooter(isOverHeader || isOverFooter);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className={`mouse-follower ${
        isOverHeaderOrFooter ? "over-header-or-footer" : ""
      }`}
      style={{
        top: position.y + 10, // Adjust to move the title slightly away from the cursor
        left: position.x + 10, // Adjust to move the title slightly away from the cursor
      }}
    >
      {mouseTitle}
    </div>
  );
};

export default MouseFollower;
