//API URL//

export const API_BASE_URL = "https://api.scanandplaytambola.com/";

// GENERAL FAQS//

export const FAQ_HEADER = "Frequently Asked Questions";

export const GEN_FAQ_QUESTION_1 = "What is numerology?";
export const GEN_FAQ_ANSWER_1 =
  "Numerology is the ancient study of numbers and their mystical influence on human life. It analyzes the numerical value of names, birthdates, and other significant factors to reveal insights about personality, destiny, and life events.";

export const GEN_FAQ_QUESTION_2 = "How does numerology work?";
export const GEN_FAQ_ANSWER_2 =
  "Numerology works by assigning numerical values to letters and numbers, then interpreting these values to uncover patterns and meanings that provide insights into various aspects of life.";

export const GEN_FAQ_QUESTION_3 = "Is numerology scientifically proven?";
export const GEN_FAQ_ANSWER_3 =
  "Numerology is not scientifically proven and is considered a metaphysical science. It is based on ancient wisdom and beliefs rather than empirical evidence.";

export const GEN_FAQ_QUESTION_4 =
  "How do I use the numerology calculators on your website?";
export const GEN_FAQ_ANSWER_4 =
  "To use our numerology calculators, select the desired tool, enter the required information (such as your name or birthdate), and click 'Calculate' to generate your result.";

export const GEN_FAQ_QUESTION_5 = "Are the numerology readings free?";
export const GEN_FAQ_ANSWER_5 =
  "All tools available on the website are free to use, while more detailed and personalized reports may require a fee.";

export const GEN_FAQ_QUESTION_6 =
  "Can I trust the accuracy of the online numerology readings?";
export const GEN_FAQ_ANSWER_6 =
  "Our online numerology readings are based on established numerological principles. While they provide valuable insights, they should be considered as guidance rather than absolute truths.";

export const GEN_FAQ_QUESTION_7 = "How often can I use the numerology tools?";
export const GEN_FAQ_ANSWER_7 =
  "You can use the numerology tools as often as you like. There is no limit to the number of times you can use our Tools.";

export const GEN_FAQ_QUESTION_8 =
  "What if I encounter technical issues while using the website?";
export const GEN_FAQ_ANSWER_8 =
  "If you experience any technical issues, please contact us at tech@EliteNumerology.com.";

export const GEN_FAQ_QUESTION_9 =
  "Can I contribute content or suggestions to the website?";
export const GEN_FAQ_ANSWER_9 =
  "We welcome user contributions and suggestions. Please reach out to us via our contact form or email us at suggestions@EliteNumerology.com with your ideas.";

export const GEN_FAQ_QUESTION_10 = "What personal information do you collect?";
export const GEN_FAQ_ANSWER_10 =
  "We collect information such as your name, birthdate, and email address to generate numerology reports and provide personalized services.";

export const GEN_FAQ_QUESTION_11 = "How do you use my personal information?";
export const GEN_FAQ_ANSWER_11 =
  "Your personal information is used solely for generating numerology reports and improving our services. We do not share your data with third parties without your consent.";

export const GEN_FAQ_QUESTION_12 =
  "What numerology tools are available on your website?";
export const GEN_FAQ_ANSWER_12 =
  "Our website offers various tools, including Kua Number Calculator, Biorhythm Chart, Mobile Number Analysis, House Number Analysis, Business Name Analysis, Partner Compatibility, Lucky Jersey Number Calculator, and many more.";

export const GEN_FAQ_QUESTION_13 =
  "What can I expect from a numerology consultation?";
export const GEN_FAQ_ANSWER_13 =
  "During a numerology consultation, you can ask regarding Date of Birth Analysis, Name Analysis, Lo Sho Grid Creation, Missing Number Remedies, Name Correction, Main Planet Number, Personalized Remedies, Lucky Number, Lucky Colours, and Questions related to Name, Health, Wealth, Career, and Relationships.";

export const GEN_FAQ_QUESTION_14 = "What payment methods do you accept?";
export const GEN_FAQ_ANSWER_14 =
  "We accept various payment methods, including UPI, credit/debit cards, PayPal, and other secure online payment options.";

export const GEN_FAQ_QUESTION_15 =
  "Is there a refund policy for paid services?";
export const GEN_FAQ_ANSWER_15 =
  "No, we don’t provide refunds for the consultation service once booked, though we can accommodate date and time changes for the consultation based on mutual understanding.";

export const GEN_FAQ_QUESTION_16 =
  "I am a numerologist. Can I use your tools to generate data for my clients?";
export const GEN_FAQ_ANSWER_16 =
  "Yes, you can use our free tools. We also provide paid APIs for most of our tools, allowing you to create your own white-labeled product.";

export const GEN_FAQ_QUESTION_17 = "Can I contribute content to the website?";
export const GEN_FAQ_ANSWER_17 =
  "Yes, we welcome user contributions. Please contact us if you are interested in submitting articles, reviews, or other content.";

export const GEN_FAQ_QUESTION_18 =
  "How long does it take to receive a response from customer support?";
export const GEN_FAQ_ANSWER_18 =
  "Our customer support team aims to respond to inquiries within 48 hours. Response times may vary depending on the complexity of the issue.";

export const GEN_FAQ_QUESTION_19 =
  "How often do you update your numerology tools?";
export const GEN_FAQ_ANSWER_19 =
  "We regularly update our numerology tools to ensure accuracy and improve user experience. Major updates are announced on our website and through email notifications.";

export const GEN_FAQ_QUESTION_20 = "Can numerology help with career decisions?";
export const GEN_FAQ_ANSWER_20 =
  "Numerology can provide insights into your strengths and weaknesses, helping you make informed career decisions that align with your natural abilities and life path.";

export const GEN_FAQ_QUESTION_21 =
  "What is the difference between numerology and astrology?";
export const GEN_FAQ_ANSWER_21 =
  "Numerology focuses on the mystical significance of numbers, while astrology is based on the positions and movements of celestial bodies. Both offer unique insights into personality and life events.";

export const GEN_FAQ_QUESTION_22 = "Can numerology improve my relationships?";
export const GEN_FAQ_ANSWER_22 =
  "Numerology can help you understand your own and your partner's personality traits, strengths, and challenges, potentially improving communication and compatibility in relationships.";

export const GEN_FAQ_QUESTION_23 = "Can numerology help with health issues?";
export const GEN_FAQ_ANSWER_23 =
  "While numerology can provide insights into potential health tendencies based on your numbers, it is not a substitute for professional medical advice and treatment.";

export const GEN_FAQ_QUESTION_24 =
  "I want to learn numerology. Do you offer any courses?";
export const GEN_FAQ_ANSWER_24 =
  "Yes, we do offer numerology courses. Please contact us via WhatsApp for more information.";

// TOOL FAQs//

export const KUA_FAQ_QUESTION_1 = "What is Kua Number?";
export const KUA_FAQ_ANSWER_1 =
  "The Kua number is a Feng Shui numerology tool used to determine your personal direction, auspicious locations, and element compatibility based on your birth year and gender.";

export const KUA_FAQ_QUESTION_2 = "How do I calculate my Kua number?";
export const KUA_FAQ_ANSWER_2 =
  "To calculate your Kua number, you need your birth year and gender. Use our Kua number calculator by entering this information to get your personalized results instantly.";

export const KUA_FAQ_QUESTION_3 =
  "Why is the Kua number important in Feng Shui?";
export const KUA_FAQ_ANSWER_3 =
  "Your Kua number helps identify your best directions for prosperity, health, and relationships, enabling you to align your living and working spaces for optimal energy flow.";

export const KUA_FAQ_QUESTION_4 = "Can my Kua number change over time?";
export const KUA_FAQ_ANSWER_4 =
  "No, your Kua number is determined by your birth year and remains constant throughout your life. It provides consistent guidance for your Feng Shui practices.";

export const KUA_FAQ_QUESTION_5 =
  "How does gender affect the Kua number calculation?";
export const KUA_FAQ_ANSWER_5 =
  "Gender affects the formula used to calculate your Kua number, leading to different results for men and women born in the same year, reflecting traditional Feng Shui principles.";

export const KUA_FAQ_QUESTION_6 =
  "How should I use my Kua Number in my home or office?"; // Initialize as empty string
export const KUA_FAQ_ANSWER_6 =
  "Use your Kua Number to orient furniture and choose rooms in your home or office that match your favorable directions. For instance, positioning your bed, desk, or seating to face one of your lucky directions can enhance positive energy flow according to Feng Shui principles."; // Initialize as empty string

export const KUA_FAQ_QUESTION_7 =
  "What if my living situation conflicts with my Kua Number?"; // Initialize as empty string
export const KUA_FAQ_ANSWER_7 =
  "If your current living arrangement conflicts with your Kua Number's recommendations, consider small adjustments like rearranging furniture or adding Feng Shui cures and enhancers to mitigate negative energies and enhance positive ones"; // Initialize as empty string

export const KUA_FAQ_QUESTION_8 =
  "Is there a cost to use the Kua Number Calculator?"; // Initialize as empty string
export const KUA_FAQ_ANSWER_8 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const KUA_FAQ_QUESTION_9 =
  "Can my Kua Number help in choosing property or real estate?"; // Initialize as empty string
export const KUA_FAQ_ANSWER_9 =
  "Absolutely! Knowing your Kua Number can guide you in selecting a property with orientations that favor your lucky directions. This is often used in Feng Shui to boost prosperity and well-being."; // Initialize as empty string

export const KUA_FAQ_QUESTION_10 =
  "Can I use my KUA number for luck and prosperity?"; // Initialize as empty string
export const KUA_FAQ_ANSWER_10 =
  "Yes, your KUA number can guide you in enhancing your luck and prosperity by aligning yourself with auspicious directions and energies, and making informed decisions about your environment and actions."; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_1 = "What is a Life Path Number?";
export const LIFE_PATH_FAQ_ANSWER_1 =
  "A Life Path Number is a key to understanding your life’s purpose, challenges, and opportunities.";

export const LIFE_PATH_FAQ_QUESTION_2 =
  "How do I calculate my Life Path Number?";
export const LIFE_PATH_FAQ_ANSWER_2 =
  "To calculate your Life Path Number, sum the digits of your birth date until you get a single digit.";

export const LIFE_PATH_FAQ_QUESTION_3 = "What does my Life Path Number mean?";
export const LIFE_PATH_FAQ_ANSWER_3 =
  "Each Life Path Number has unique traits and life lessons that shape your personality and destiny.";

export const LIFE_PATH_FAQ_QUESTION_4 = " Can I change my Life Path Number?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_4 =
  " No, your Life Path Number is calculated from your birth date and remains the same throughout your life."; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_5 =
  " How does my Life Path Number relate to my personality?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_5 =
  "Your Life Path Number reflects aspects of your personality, strengths, and challenges, but it's not the only factor influencing your personality."; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_6 =
  "Can Life Path Numbers predict my future?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_6 =
  "While Life Path Numbers offer insights into life's themes and potential, they don't predict specific events or guarantee success. They serve as guidance for personal growth and self-awareness."; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_7 =
  "Is there a cost to use the Life Path Number Calculator?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_7 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_8 =
  "How does my Life Path Number influence my career choices?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_8 =
  "Your Life Path Number can guide you towards suitable career paths aligning with your strengths, passions, and life purpose, leading to a more fulfilling professional life."; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_9 =
  " Are Life Path Numbers connected to astrology?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_9 =
  "While Life Path Numbers and astrology share some similarities, they are distinct systems. Life Path Numbers focus on numerological values, whereas astrology explores planetary positions and their influences."; // Initialize as empty string

export const LIFE_PATH_FAQ_QUESTION_10 =
  "Are Life Path Numbers scientifically proven?"; // Initialize as empty string
export const LIFE_PATH_FAQ_ANSWER_10 =
  "Life Path Numbers are based on numerological principles, which are not scientifically proven. However, many people find value and insights in this ancient practice, using it as a tool for personal guidance and understanding."; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_1 = "What is a Personal Year Number?";
export const PERSONAL_YEAR_FAQ_ANSWER_1 =
  "A Personal Year Number provides insight into the theme and opportunities of the current year for an individual.";

export const PERSONAL_YEAR_FAQ_QUESTION_2 =
  "How do I calculate my Personal Year Number?";
export const PERSONAL_YEAR_FAQ_ANSWER_2 =
  "To calculate your Personal Year Number, add your birth month and day to the current year, and reduce it to a single digit.";

export const PERSONAL_YEAR_FAQ_QUESTION_3 =
  "What does my Personal Year Number indicate?";
export const PERSONAL_YEAR_FAQ_ANSWER_3 =
  "Each Personal Year Number has specific traits and themes that influence your experiences and growth throughout the year.";

export const PERSONAL_YEAR_FAQ_QUESTION_4 =
  "Can I use my Personal Year Number to understand past experiences?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_4 =
  "Yes, reflecting on past Personal Year Numbers can help you understand previous challenges, successes, and lessons. This insight can promote personal growth, closure, and a deeper understanding of your life journey."; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_5 =
  "Are Personal Year Numbers the same for everyone born in the same year?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_5 =
  "No, Personal Year Numbers are unique to each individual, based on their birth date and current year. While people born in the same year share similar global energies, their Personal Year Numbers reflect their individual journey."; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_6 =
  "How does my Personal Year Number impact my relationships?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_6 =
  "Your Personal Year Number can influence your relationship dynamics, revealing opportunities for growth, harmony, or transformation. It can also highlight areas for personal development, leading to stronger connections with others."; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_7 =
  "Is there a cost to use the Personal Year Number Calculator?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_7 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_8 =
  " Should I make important decisions based on my Personal Year Number?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_8 =
  "While your Personal Year Number provides insights, it's essential to use your judgment and intuition when making important decisions, considering both the numerical guidance and your personal circumstances."; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_9 =
  "How long does a Personal Year cycle last?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_9 =
  "A Personal Year cycle lasts from January 1st to December 31st of each year."; // Initialize as empty string

export const PERSONAL_YEAR_FAQ_QUESTION_10 =
  "How does my Personal Year Number relate to my Life Path Number?"; // Initialize as empty string
export const PERSONAL_YEAR_FAQ_ANSWER_10 =
  "Your Personal Year Number is a shorter-term cycle that complements your Life Path Number, which is a longer-term life journey. Think of it like a chapter in a book, where your Life Path Number is the overall story."; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_1 = "What is the Lo Shu Grid?";
export const LO_SHU_FAQ_ANSWER_1 =
  "The Lo Shu Grid, also known as the Magic Square, is a 3x3 grid where the sum of each row, column, and diagonal equals the same number.";

export const LO_SHU_FAQ_QUESTION_2 =
  "How does the Lo Shu Grid work in numerology?";
export const LO_SHU_FAQ_ANSWER_2 =
  "In numerology, the Lo Shu Grid is used to determine various aspects of personality, strengths, and weaknesses based on one's birthdate.";

export const LO_SHU_FAQ_QUESTION_3 =
  "What are the meanings of the numbers in the Lo Shu Grid?";
export const LO_SHU_FAQ_ANSWER_3 =
  "Each number in the Lo Shu Grid has specific symbolic meanings and associations that can provide insights into different aspects of life.";

export const LO_SHU_FAQ_QUESTION_4 =
  "What is a Difference between Fortune Grid and Lo sho Grid?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_4 =
  "Both are Same , there is no Difference between the both "; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_5 =
  "Is there a cost to use the Lo Shu Grid Calculator?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_5 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_6 =
  "Can the Lo Shu Grid help me make important decisions?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_6 =
  "Yes, the Lo Shu Grid provides guidance on various aspects of your life, helping you make informed decisions aligned with your numerological influences and life path."; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_7 =
  "How often should I recalculate my Lo Shu Grid?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_7 =
  "Your Lo Shu Grid remains the same throughout your life, but you may want to recalculate it annually to reflect changes in your Personal Year Number and its influence on your grid."; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_8 =
  "Can I use the Lo Shu Grid for self-discovery?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_8 =
  "Yes, the Lo Shu Grid offers valuable insights into your personality, strengths, and challenges, promoting self-awareness and personal growth."; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_9 =
  " Is the Lo Shu Grid related to other numerological systems?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_9 =
  "Yes, the Lo Shu Grid shares connections with other numerological systems, such as Life Path Numbers and Personal Year Numbers, offering a comprehensive understanding of numerological influences in your life."; // Initialize as empty string

export const LO_SHU_FAQ_QUESTION_10 = "Can the Lo Shu Grid predict my future?"; // Initialize as empty string
export const LO_SHU_FAQ_ANSWER_10 =
  "While the Lo Shu Grid offers insights into your life's journey, it doesn't predict specific events or guarantee success. It serves as a tool for self-discovery and guidance."; // Initialize as empty string

export const FASTING_FAQ_QUESTION_1 =
  "What is fasting in the context of numerology?";
export const FASTING_FAQ_ANSWER_1 =
  "Fasting in numerology refers to specific days or periods believed to have spiritual significance or influence on personal energies based on numerological principles.";

export const FASTING_FAQ_QUESTION_2 =
  "How does fasting relate to numerology practices?";
export const FASTING_FAQ_ANSWER_2 =
  "In numerology, fasting is often associated with enhancing spiritual connections, focusing personal energies, or aligning with favorable numerological influences.";

export const FASTING_FAQ_QUESTION_3 =
  "What are the different types of fasting days in numerology?";
export const FASTING_FAQ_ANSWER_3 =
  "Numerology identifies various fasting days based on calculations involving personal numbers or specific dates that hold significance.";

export const FASTING_FAQ_QUESTION_4 =
  "Is there a cost to use the Fasting Day Calculator?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_4 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const FASTING_FAQ_QUESTION_5 =
  "Are there any special considerations for health or medical conditions?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_5 =
  "We provide the above information based on Numerological Advice , however to Start andy Fast regime plesae consult your healthcare provider"; // Initialize as empty string

export const FASTING_FAQ_QUESTION_6 =
  "Is the calculator accurate for different time zones?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_6 =
  "For this Calculator to work , there is no need of Time Zone calculation , so the results are accurate as per provided DOb and Name"; // Initialize as empty string

export const FASTING_FAQ_QUESTION_7 =
  "What fasting patterns does the calculator support?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_7 =
  "The tools takes in account your DOB and Name to Calculate Best Fasting Day for Success , and it doesn't takes into account of Fasting calender as per your religion."; // Initialize as empty string

export const FASTING_FAQ_QUESTION_8 = "What is a fasting day calculator?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_8 =
  "A fasting day calculator helps you determine specific day of the week when you should fast based on your Date of Birth , which will help you in gaining Success"; // Initialize as empty string

export const FASTING_FAQ_QUESTION_9 =
  "Can I use the Fasting Day Calculator for religious fasting practices?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_9 =
  "While the Fasting Day Calculator provides recommendations based on numerology, it does not account for specific religious fasting practices. It's best to follow your religious guidelines for those practices."; // Initialize as empty string

export const FASTING_FAQ_QUESTION_10 =
  "How often should I use the Fasting Day Calculator?"; // Initialize as empty string
export const FASTING_FAQ_ANSWER_10 =
  "You can use the Fasting Day Calculator whenever you feel the need to align your fasting days with numerological principles. There's no set frequency, but some people check it monthly or during significant life events."; // Initialize as empty string

// Vehicle FAQs as single constants
export const VEHICLE_FAQ_QUESTION_1 =
  "What is a Lucky Vehicle Number in numerology?";
export const VEHICLE_FAQ_ANSWER_1 =
  "A Lucky Vehicle Number is a number believed to bring luck and positive energies when associated with a vehicle, based on numerological calculations.";

export const VEHICLE_FAQ_QUESTION_2 =
  "How do I calculate my Lucky Vehicle Number?";
export const VEHICLE_FAQ_ANSWER_2 =
  "To calculate your Lucky Vehicle Number, you often sum up the digits of your vehicle registration number or use your birth date to find a compatible number.";

export const VEHICLE_FAQ_QUESTION_3 =
  "What are the benefits of having a Lucky Vehicle Number?";
export const VEHICLE_FAQ_ANSWER_3 =
  "Having a Lucky Vehicle Number is believed to enhance safety, reduce accidents, and attract positive experiences while traveling.";

export const VEHICLE_FAQ_QUESTION_4 =
  "Can I use the Lucky Vehicle Number for any vehicle?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_4 =
  "Yes, the calculator works for cars, trucks, motorcycles, and any other vehicle with a registration number."; // Initialize as empty string

export const VEHICLE_FAQ_QUESTION_5 =
  "How does the Lucky Vehicle Number affect my life?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_5 =
  "Your Lucky Vehicle Number is believed to influence your journey, travel, and overall luck, but it's essential to remember this doesn't guarantee a good fortune."; // Initialize as empty string

export const VEHICLE_FAQ_QUESTION_6 = "Can I change my Lucky Vehicle Number?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_6 =
  "No, your Lucky Vehicle Number is fixed and unique to your vehicle's registration number. If you change vehicles, you can calculate a new Lucky Vehicle Number , but we do provide Remedies which you can implement "; // Initialize as empty string

export const VEHICLE_FAQ_QUESTION_7 =
  "How often should I calculate my Lucky Vehicle Number?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_7 =
  "You only need to calculate your Lucky Vehicle Number once, as it remains the same for your vehicle's registration number. We prefer checking this tool before you are Purchasing a new Vehicle"; // Initialize as empty string

export const VEHICLE_FAQ_QUESTION_8 =
  "Can the Lucky Vehicle Number Calculator help with used vehicles?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_8 =
  "Yes, you can use the calculator for used vehicles to select a number plate that aligns with your numerology, improving the vehicle’s energy compatibility with you"; // Initialize as empty string

export const VEHICLE_FAQ_QUESTION_9 =
  "What should I do if I can't get the exact number suggested?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_9 =
  "If the exact number isn't available, aim for a close variant. Numbers that sum up to or reduce to the same single digit suggested by the calculator will still carry beneficial energies."; // Initialize as empty string

export const VEHICLE_FAQ_QUESTION_10 =
  "Is there a cost to use the Lucky Vehicle Number Calculator?"; // Initialize as empty string
export const VEHICLE_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

// In ../components/Constants.js

export const DESTINY_FAQ_QUESTION_1 = "What is a Destiny Number?";
export const DESTINY_FAQ_ANSWER_1 =
  "A Destiny Number, also known as the Expression Number, is a numerical value calculated from your full birth name, revealing your life's purpose, personality, and potential.";

export const DESTINY_FAQ_QUESTION_2 =
  "How does my Destiny Number relate to my life purpose?";
export const DESTINY_FAQ_ANSWER_2 =
  "Your Destiny Number reveals your potential, strengths, and challenges, offering insights into your life's journey and purpose.";

export const DESTINY_FAQ_QUESTION_3 = "Can I change my Destiny Number?";
export const DESTINY_FAQ_ANSWER_3 =
  "No, your Destiny Number is fixed and unique to your birth name, and remains the same throughout your life.";

export const DESTINY_FAQ_QUESTION_4 =
  "How does the Destiny Number differ from the Life Path Number?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_4 =
  "While both numbers share similarities, the Destiny Number focuses on your life's purpose and personality, whereas the Life Path Number emphasizes your life journey and experiences."; // Initialize as empty string

export const DESTINY_FAQ_QUESTION_5 =
  "Can I use the Destiny Number for self-discovery?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_5 =
  "Yes, the Destiny Number offers valuable insights into your strengths, weaknesses, and potential, promoting self-awareness and personal growth."; // Initialize as empty string

export const DESTINY_FAQ_QUESTION_6 =
  "Can my Destiny Number change if I legally change my name?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_6 =
  "Yes, a legal name change can alter your Destiny Number, as the calculation is based on the letters in your full birth name."; // Initialize as empty string

export const DESTINY_FAQ_QUESTION_7 =
  "Can I use the Destiny Number Calculator for someone else?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_7 =
  "Yes, you can calculate the Destiny Number for friends, family, or public figures, but remember that their number is personal to them."; // Initialize as empty string

export const DESTINY_FAQ_QUESTION_8 =
  "How does the Destiny Number relate to relationships?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_8 =
  "Your Destiny Number can reveal insights into your relationship strengths, challenges, and potential, helping you navigate romantic and professional partnerships."; // Initialize as empty string

export const DESTINY_FAQ_QUESTION_9 =
  "How does the Destiny Number impact my career choices?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_9 =
  "Your Destiny Number can guide you towards suitable career paths aligning with your strengths, passions, and life purpose."; // Initialize as empty string

export const DESTINY_FAQ_QUESTION_10 =
  "Is there a cost to use the Destiny Number Calculator?"; // Initialize as empty string
export const DESTINY_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

// In ../components/Constants.js

export const SOUL_FAQ_QUESTION_1 = "What is a Soul Urge Number in numerology?";
export const SOUL_FAQ_ANSWER_1 =
  "A Soul Urge Number, also known as the Heart's Desire Number, is derived from the vowels in your full birth name. It reveals your inner self, desires, motivations, and the aspects of life that bring you joy and fulfillment.";

export const SOUL_FAQ_QUESTION_2 = "How do I calculate my Soul Urge Number?";
export const SOUL_FAQ_ANSWER_2 =
  "To calculate your Soul Urge Number, assign numerical values to the vowels in your full birth name and then reduce these values to a single digit or a master number (11, 22, etc.).";

export const SOUL_FAQ_QUESTION_3 =
  "What insights does a Soul Urge Number provide?";
export const SOUL_FAQ_ANSWER_3 =
  "Your Soul Urge Number provides insights into your deepest desires, passions, and inner motivations. It reflects what truly drives you at a soul level and what you yearn for in life.";

export const SOUL_FAQ_QUESTION_4 = "Can my Soul Urge Number change over time?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_4 =
  "No, your Soul Urge Number remains constant throughout your life and reflects your core inner desires and motivations, which do not change."; // Initialize as empty string

export const SOUL_FAQ_QUESTION_5 =
  "What if my name changes after marriage or for other reasons?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_5 =
  "The Soul Urge Number is based on your birth name, not any name changes that occur later in life."; // Initialize as empty string

export const SOUL_FAQ_QUESTION_6 =
  "Can two people have the same Soul Urge Number?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_6 =
  "Yes, it’s possible for two people to have the same Soul Urge Number. While the number itself may be the same, how it manifests in each person's life can be different due to individual personalities, experiences, and other numerological influences."; // Initialize as empty string

export const SOUL_FAQ_QUESTION_7 =
  "How does the Soul Urge Number influence relationships?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_7 =
  "Understanding your Soul Urge Number can provide valuable insights into your relationship dynamics, including what you truly desire from a partner and what you need to feel fulfilled in a relationship."; // Initialize as empty string

export const SOUL_FAQ_QUESTION_8 =
  "Can my Soul Urge Number help in career choices?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_8 =
  "Yes, your Soul Urge Number can reveal the type of work environment and career that aligns with your inner desires and motivations."; // Initialize as empty string

export const SOUL_FAQ_QUESTION_9 =
  "What is the difference between a Soul Urge Number and a Life Path Number?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_9 =
  "The Soul Urge Number reveals your innermost desires and motivations, while the Life Path Number provides insights into your overall journey and purpose in life."; // Initialize as empty string

export const SOUL_FAQ_QUESTION_10 =
  "Is there a cost to use the Soul Urge Number Calculator?"; // Initialize as empty string
export const SOUL_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_1 =
  "What is a Monthly Biorhythm Predictor?";
export const MONTHLY_BIO_FAQ_ANSWER_1 =
  "The Monthly Biorhythm Predictor is a tool that calculates and visualizes your physical, emotional, and intellectual cycles based on your date of birth, offering insights into your well-being and optimal times for various activities.";

export const MONTHLY_BIO_FAQ_QUESTION_2 =
  "How do I calculate my Monthly Biorhythm?";
export const MONTHLY_BIO_FAQ_ANSWER_2 =
  "To calculate your Monthly Biorhythm, you need your birthdate. Each cycle (physical, emotional, intellectual) is calculated separately using specific formulas, often involving sinusoidal waves.";

export const MONTHLY_BIO_FAQ_QUESTION_3 =
  "What do the physical, emotional, and intellectual aspects represent?";
export const MONTHLY_BIO_FAQ_ANSWER_3 =
  "The physical aspect reflects your energy levels and physical well-being, the emotional aspect indicates your mood and emotional stability, while the intellectual aspect signifies cognitive function and mental acuity.";

export const MONTHLY_BIO_FAQ_QUESTION_4 =
  "What are critical days in the biorhythm calculator?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_4 =
  "Critical days are days when the biorhythm curve passes the zero line, which can affect a person's physical and intellectual abilities, mood, and overall well-being."; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_5 = "Can biorhythms predict my future?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_5 =
  "Biorhythms are not a reliable method for predicting the future, but rather a tool for understanding your physical, emotional, and intellectual cycles."; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_6 =
  "Can biorhythms be used for health and wellness?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_6 =
  "Biorhythms can help you understand your physical and emotional cycles, which can be useful for planning exercise, meditation, or other self-care activities."; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_7 =
  "Can biorhythms be used for decision-making?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_7 =
  "While biorhythms can provide insights into your cycles, they shouldn't be the sole basis for making important decisions."; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_8 =
  "Are biorhythms universal or individual?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_8 =
  "Biorhythms are individual and unique to each person, based on their birth date and personal cycles."; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_9 =
  "Can biorhythms be used in relationships?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_9 =
  "Understanding your partner's biorhythms can help you navigate relationships and communicate more effectively."; // Initialize as empty string

export const MONTHLY_BIO_FAQ_QUESTION_10 =
  "Is there a cost to use the Monthly Biorhythm Chart Calculator?"; // Initialize as empty string
export const MONTHLY_BIO_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_1 =
  "What is a Lucky Mobile Number in numerology?";
export const MOBILE_FAQ_ANSWER_1 =
  "A Lucky Mobile Number is a number believed to bring luck and positive energies when associated with a mobile phone, based on numerological calculations.";

export const MOBILE_FAQ_QUESTION_2 =
  "How do I calculate my Lucky Mobile Number?";
export const MOBILE_FAQ_ANSWER_2 =
  "To calculate your Lucky Mobile Number, you often sum up the digits of your mobile number or use your birth date to find a compatible number.";

export const MOBILE_FAQ_QUESTION_3 =
  "What are the benefits of having a Lucky Mobile Number?";
export const MOBILE_FAQ_ANSWER_3 =
  "Having a Lucky Mobile Number is believed to enhance communication, attract positive opportunities through phone calls or messages, and improve overall connectivity.";

export const MOBILE_FAQ_QUESTION_4 =
  "Can I use the lucky mobile number calculator for any mobile number?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_4 =
  "Yes, you can use the calculator for any mobile number, regardless of the country or network."; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_5 =
  "Can changing my current mobile number to a luckier one make a difference?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_5 =
  "Yes, changing to a number that aligns better with your numerological chart can improve the vibrational quality of your communications and might attract more positive experiences in daily life."; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_6 =
  "What should I do if the suggested numbers are not available?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_6 =
  "If your ideal numbers are unavailable, try variations that sum up to a similar numerological value, as these can also harness beneficial energies and offer similar advantages."; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_7 =
  "Can I use the lucky mobile number calculator for business purposes?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_7 =
  "Yes, you can use the calculator to find a lucky mobile number for your business or company."; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_8 =
  "How often should I use the lucky mobile number calculator?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_8 =
  "You can use the calculator as needed, such as when getting a new mobile number or considering a number change."; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_9 =
  "What is the significance of a lucky mobile number?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_9 =
  "A lucky mobile number is believed to attract positive energy, bring good luck, and enhance the owner's life in various ways."; // Initialize as empty string

export const MOBILE_FAQ_QUESTION_10 =
  "Is there a cost to use the Lucky Mobile Number Calculator?"; // Initialize as empty string
export const MOBILE_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_1 =
  "What is a Lucky House Number in numerology?";
export const HOUSE_FAQ_ANSWER_1 =
  "A Lucky House Number is a number believed to bring luck and positive energies when associated with a residential property, based on numerological calculations.";

export const HOUSE_FAQ_QUESTION_2 = "How do I calculate my Lucky House Number?";
export const HOUSE_FAQ_ANSWER_2 =
  "To calculate your Lucky House Number, you often sum up the digits of your house number or use your birth date to find a compatible number.";

export const HOUSE_FAQ_QUESTION_3 =
  "What are the benefits of having a Lucky House Number?";
export const HOUSE_FAQ_ANSWER_3 =
  "Having a Lucky House Number is believed to enhance harmony, prosperity, and overall well-being within the household.";

export const HOUSE_FAQ_QUESTION_4 =
  "What is the significance of a lucky house number?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_4 =
  "A lucky house number is believed to attract positive energy, bring good luck, and enhance the well-being of the occupants."; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_5 =
  "Can I use the lucky house number calculator for any address?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_5 =
  "Yes, you can use the calculator for any residential address, including apartments and condos."; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_6 =
  "Can I change my house number to a lucky one?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_6 =
  "Yes, if you're not satisfied with your current house number, you can use the calculator to find a luckier one and consider changing your house number accordingly."; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_7 =
  "Can I use the lucky house number calculator for commercial properties?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_7 =
  "Yes, you can use the calculator for commercial properties, such as offices or retail stores."; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_8 =
  "Does the lucky house number calculator consider the architecture of the house?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_8 =
  "No, the calculator does not consider the architecture of the house, but rather focuses on the numerical values and astrological factors."; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_9 =
  "How often should I use the lucky house number calculator?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_9 =
  "You can use the calculator as needed, such as when moving to a new home or considering a renovation."; // Initialize as empty string

export const HOUSE_FAQ_QUESTION_10 =
  "Is there a cost to use the Lucky House Number Calculator?"; // Initialize as empty string
export const HOUSE_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const BANK_FAQ_QUESTION_1 =
  "What is a Lucky Bank Account Number in numerology?";
export const BANK_FAQ_ANSWER_1 =
  "A Lucky Bank Account Number is a number believed to bring financial luck and positive energies when associated with a bank account, based on numerological calculations.";

export const BANK_FAQ_QUESTION_2 =
  "How do I calculate my Lucky Bank Account Number?";
export const BANK_FAQ_ANSWER_2 =
  "To calculate your Lucky Bank Account Number, you often sum up the digits of your bank account number or use your birth date to find a compatible number.";

export const BANK_FAQ_QUESTION_3 =
  "What are the benefits of having a Lucky Bank Account Number?";
export const BANK_FAQ_ANSWER_3 =
  "Having a Lucky Bank Account Number is believed to attract financial stability, growth, and prosperity.";

export const BANK_FAQ_QUESTION_4 =
  "Can I use the lucky bank account number calculator for any bank account?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_4 =
  "Yes, you can use the calculator for any type of bank account, including checking, savings, credit cards, and loans."; // Initialize as empty string

export const BANK_FAQ_QUESTION_5 =
  "Does the lucky bank account number calculator consider my personal details?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_5 =
  "Yes, the calculator takes into account your name, birth date, and other personal details to provide a personalized lucky bank account number analysis."; // Initialize as empty string

export const BANK_FAQ_QUESTION_6 =
  "Can I use the lucky bank account number calculator for business bank accounts?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_6 =
  "Yes, you can use the calculator for business bank accounts to determine a lucky account number for your company."; // Initialize as empty string

export const BANK_FAQ_QUESTION_7 =
  "Can changing my current bank account number to a luckier one make a difference?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_7 =
  "Numerology enthusiasts believe that changing to a luckier bank account number can positively influence your financial energies, possibly leading to increased wealth and reduced financial stress."; // Initialize as empty string

export const BANK_FAQ_QUESTION_8 =
  "Is there a cost to use the Lucky Bank Account Number Calculator?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_8 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const BANK_FAQ_QUESTION_9 =
  "What if I cannot get the exact lucky number suggested for my bank account?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_9 =
  "If the exact suggested number is unavailable, aim for numbers that have similar numerological attributes, such as those that sum up to or reduce to the same digit, to still benefit from favorable vibrations."; // Initialize as empty string

export const BANK_FAQ_QUESTION_10 =
  "Why should I consider a lucky number for my bank account?"; // Initialize as empty string
export const BANK_FAQ_ANSWER_10 =
  "A lucky bank account number, as per numerology, can help align financial transactions with positive energy flows, potentially leading to better financial stability and growth."; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_1 =
  "What is a Lucky Business Name in numerology?";
export const BUSINESS_FAQ_ANSWER_1 =
  "A Lucky Business Name is a name believed to bring luck and positive energies to a business, based on numerological calculations.";

export const BUSINESS_FAQ_QUESTION_2 = "How do I choose a Lucky Business Name?";
export const BUSINESS_FAQ_ANSWER_2 =
  "To choose a Lucky Business Name, you can use numerology to calculate the compatibility of names with your business's purpose and goals.";

export const BUSINESS_FAQ_QUESTION_3 =
  "What are the benefits of having a Lucky Business Name?";
export const BUSINESS_FAQ_ANSWER_3 =
  "Having a Lucky Business Name is believed to attract success, prosperity, and positive reputation to the business.";

export const BUSINESS_FAQ_QUESTION_4 =
  "Can I use the lucky business name calculator for any business?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_4 =
  "Yes, you can use the calculator for any type of business, including sole proprietorships, partnerships, corporations, and non-profit organizations."; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_5 =
  "How accurate is the lucky business name calculator?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_5 =
  "The accuracy of the calculator depends on the accuracy of the information provided."; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_6 =
  "What if my business name number isn't favorable?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_6 =
  "If your business name number isn't favorable, consider tweaking the name slightly or adding elements to align with a more auspicious number, enhancing its overall vibrational energy."; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_7 =
  "Can I use the lucky business name calculator for a new business venture?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_7 =
  "Yes, you can use the calculator to determine a lucky name for a new business venture."; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_8 =
  "Can I use the lucky business name calculator for a business rebrand?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_8 =
  "Yes, you can use the calculator to determine a lucky name for a business rebrand or renaming."; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_9 =
  "Is there a cost to use the Lucky Business Name Calculator?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_9 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const BUSINESS_FAQ_QUESTION_10 =
  "Can I use the calculator for a business that is not yet established?"; // Initialize as empty string
export const BUSINESS_FAQ_ANSWER_10 =
  "Yes, you can use the calculator for a business that is not yet established, and it will provide a lucky name based on your plans and intentions."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_1 =
  "What is Partner Compatibility in numerology?";
export const PARTNER_FAQ_ANSWER_1 =
  "Partner Compatibility in numerology refers to the compatibility between two individuals based on their numerological profiles, such as Life Path Numbers, Destiny Numbers, and other factors.";

export const PARTNER_FAQ_QUESTION_2 =
  "How is Partner Compatibility calculated?";
export const PARTNER_FAQ_ANSWER_2 =
  "Partner Compatibility is often calculated by comparing numerological aspects like Life Path Numbers and other relevant numbers to determine potential harmony and challenges in a relationship.";

export const PARTNER_FAQ_QUESTION_3 =
  "What numbers are important for Partner Compatibility?";
export const PARTNER_FAQ_ANSWER_3 =
  "Important numbers for Partner Compatibility include Life Path Numbers, Destiny Numbers, and sometimes other numerological factors like Soul Urge Numbers or Personality Numbers.";

export const PARTNER_FAQ_QUESTION_4 =
  "How can I use partner compatibility to improve my relationship?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_4 =
  "By understanding your partner's strengths, weaknesses, and compatibility with you, you can work together to build a stronger and more fulfilling relationship."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_5 =
  "Is partner compatibility only for romantic relationships?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_5 =
  "No, partner compatibility can also be used for business partnerships, friendships, and other types of relationships."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_6 =
  "How can I use partner compatibility to resolve conflicts in my relationship?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_6 =
  "By understanding your partner's perspective and compatibility factors, you can communicate more effectively and work through conflicts together."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_7 =
  "Can partner compatibility predict the success of a relationship?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_7 =
  "While partner compatibility can provide insights into the potential success of a relationship, it is not a guarantee of success or failure."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_8 = "How accurate is partner compatibility?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_8 =
  "Partner compatibility is not an exact science, but rather a guide to help individuals understand their potential compatibility with others."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_9 =
  "Can partner compatibility be used for self-reflection and personal growth?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_9 =
  "Yes, partner compatibility can help you understand your own strengths, weaknesses, and areas for personal growth."; // Initialize as empty string

export const PARTNER_FAQ_QUESTION_10 =
  "Is there a cost to use the Partner Compatibility Calculator?"; // Initialize as empty string
export const PARTNER_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const KUACOMPATIBILITY_FAQ_QUESTION_1 = "What is a Kua number?";
export const KUACOMPATIBILITY_FAQ_ANSWER_1 =
  "A Kua number is a key element in Feng Shui used to determine compatibility between individuals based on their date of birth and gender. It is believed to influence personal traits and relationship dynamics.";

export const KUACOMPATIBILITY_FAQ_QUESTION_2 = "How is my Kua number calculated?";
export const KUACOMPATIBILITY_FAQ_ANSWER_2 =
  "Your Kua number is calculated using your date of birth and gender. For males, it's calculated using the year of birth, while for females, it’s calculated by subtracting the year of birth from 100, then adding 10 and reducing the result to a single digit.";

export const KUACOMPATIBILITY_FAQ_QUESTION_3 =
  "Why do I need to enter the gender of both partners?";
export const KUACOMPATIBILITY_FAQ_ANSWER_3 =
  "Gender is used in the calculation of the Kua number as it affects the result differently for males and females. This ensures accurate compatibility analysis.";

export const KUACOMPATIBILITY_FAQ_QUESTION_4 =
  "Can the Kua number be used to predict the success of a relationship?";
export const KUACOMPATIBILITY_FAQ_ANSWER_4 =
  "The Kua number can provide insights into compatibility and potential challenges based on Feng Shui principles, but it should be used as a supplementary tool rather than a definitive predictor of relationship success.";

export const KUACOMPATIBILITY_FAQ_QUESTION_5 =
  "What if the date of birth I enter is incorrect?";
export const KUACOMPATIBILITY_FAQ_ANSWER_5 =
  "Ensure the date of birth is accurate for the most accurate Kua number calculation. Incorrect dates will lead to incorrect Kua numbers and compatibility results.";

export const KUACOMPATIBILITY_FAQ_QUESTION_6 =
  "Are there any special considerations if one partner’s birth year is before 1900?";
export const KUACOMPATIBILITY_FAQ_ANSWER_6 =
  "The Kua number calculation typically applies to modern dates. If a birth year is significantly historical, consider consulting with a Feng Shui expert for personalized guidance.";

export const KUACOMPATIBILITY_FAQ_QUESTION_7 =
  "Can Kua number compatibility be used for business partnerships or friendships?";
export const KUACOMPATIBILITY_FAQ_ANSWER_7 =
  "Yes, Kua number compatibility can be applied to various relationships, including business partnerships and friendships, to understand dynamics and enhance cooperation.";

export const KUACOMPATIBILITY_FAQ_QUESTION_8 =
  "Is Kua number compatibility the only factor in determining relationship harmony?";
export const KUACOMPATIBILITY_FAQ_ANSWER_8 =
  "While Kua number compatibility provides valuable insights, it is one of many factors affecting relationship harmony. Communication, shared values, and mutual respect are also crucial.";

export const KUACOMPATIBILITY_FAQ_QUESTION_9 =
  "How do I interpret the compatibility results?";
export const KUACOMPATIBILITY_FAQ_ANSWER_9 =
  "The compatibility results will indicate how well the Kua numbers of both partners align. Generally, favorable matches suggest harmonious interactions, while challenging matches may highlight areas for growth and improvement.";

export const KUACOMPATIBILITY_FAQ_QUESTION_10 =
  "What should I do if the compatibility results suggest challenges?";
export const KUACOMPATIBILITY_FAQ_ANSWER_10 =
  "If the results indicate potential challenges, use the insights as a starting point for improving understanding and communication. Feng Shui is about enhancing awareness and working on relationship aspects rather than predicting inevitable outcomes.";

export const MATURITY_FAQ_QUESTION_1 = "What is a Maturity Number?";
export const MATURITY_FAQ_ANSWER_1 =
  "A Maturity Number is a numerological value that represents a person's spiritual maturity and life journey.";

export const MATURITY_FAQ_QUESTION_2 = "What does the Maturity Number reveal?";
export const MATURITY_FAQ_ANSWER_2 =
  "The Maturity Number reveals a person's level of spiritual awareness, emotional intelligence, and life purpose.";

export const MATURITY_FAQ_QUESTION_3 =
  "How does the Maturity Number differ from other numerological numbers?";
export const MATURITY_FAQ_ANSWER_3 =
  "The Maturity Number is distinct from other numbers, like the Life Path Number and Soul Urge Number, as it focuses on spiritual growth and life journey.";

export const MATURITY_FAQ_QUESTION_4 = "Can the Maturity Number change?";
export const MATURITY_FAQ_ANSWER_4 =
  "No, the Maturity Number remains the same throughout a person's life, as it's based on their birth date.";

export const MATURITY_FAQ_QUESTION_5 =
  "How does the Maturity Number impact a person's life?";
export const MATURITY_FAQ_ANSWER_5 =
  "The Maturity Number influences a person's relationships, career, and overall life path, guiding them towards spiritual growth and self-awareness.";

export const MATURITY_FAQ_QUESTION_6 =
  "Is the Maturity Number related to age or maturity level?";
export const MATURITY_FAQ_ANSWER_6 =
  "No, the Maturity Number is not directly related to age or maturity level, as it's a numerological value that transcends chronological age.";

export const MATURITY_FAQ_QUESTION_7 =
  "Can the Maturity Number be used for personal growth?";
export const MATURITY_FAQ_ANSWER_7 =
  "Yes, understanding your Maturity Number can help you identify areas for personal growth, spiritual development, and life purpose.";

export const MATURITY_FAQ_QUESTION_8 =
  "How does the Maturity Number impact relationships?";
export const MATURITY_FAQ_ANSWER_8 =
  "The Maturity Number can influence a person's relationships, as it reveals their capacity for emotional intelligence, empathy, and spiritual connection.";

export const MATURITY_FAQ_QUESTION_9 =
  "Can the Maturity Number be used for career guidance?"; // Initialize as empty string
export const MATURITY_FAQ_ANSWER_9 =
  "Yes, the Maturity Number can provide insights into a person's career path, helping them find a profession that aligns with their spiritual purpose."; // Initialize as empty string

export const MATURITY_FAQ_QUESTION_10 =
  "Is there a cost to use the Maturity Number Calculator?"; // Initialize as empty string
export const MATURITY_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const PINNACLE_FAQ_QUESTION_1 =
  "What are Pinnacle Numbers in numerology?";
export const PINNACLE_FAQ_ANSWER_1 =
  "Pinnacle Numbers in numerology represent different phases or cycles of your life journey, each with its own lessons and opportunities.";

export const PINNACLE_FAQ_QUESTION_2 = "How many Pinnacle Numbers do I have?";
export const PINNACLE_FAQ_ANSWER_2 =
  "You typically have four Pinnacle Numbers throughout your life, each indicating a significant period or phase.";

export const PINNACLE_FAQ_QUESTION_3 = "How are Pinnacle Numbers calculated?";
export const PINNACLE_FAQ_ANSWER_3 =
  "Pinnacle Numbers are calculated using your birthdate and full birth name, applying numerological calculations to determine each Pinnacle's characteristics.";

export const PINNACLE_FAQ_QUESTION_4 = "What does the Pinnacle Number reveal?";
export const PINNACLE_FAQ_ANSWER_4 =
  "The Pinnacle Number reveals a person's spiritual potential, life purpose, and soul mission.";

export const PINNACLE_FAQ_QUESTION_5 =
  "How does the Pinnacle Number differ from other numerological numbers?";
export const PINNACLE_FAQ_ANSWER_5 =
  "The Pinnacle Number is distinct from other numbers, like the Life Path Number and Soul Urge Number, as it focuses on a person's highest potential and life purpose.";

export const PINNACLE_FAQ_QUESTION_6 = "Can the Pinnacle Number change?";
export const PINNACLE_FAQ_ANSWER_6 =
  "No, the Pinnacle Number remains the same throughout a person's life, as it's based on their birth date.";

export const PINNACLE_FAQ_QUESTION_7 =
  "How does the Pinnacle Number impact a person's life?";
export const PINNACLE_FAQ_ANSWER_7 =
  "The Pinnacle Number influences a person's relationships, career, and overall life path, guiding them towards their highest potential.";

export const PINNACLE_FAQ_QUESTION_8 =
  "Is the Pinnacle Number related to a person's age or life stage?";
export const PINNACLE_FAQ_ANSWER_8 =
  "No, the Pinnacle Number is not directly related to age or life stage, as it's a numerological value that transcends chronological age.";

export const PINNACLE_FAQ_QUESTION_9 =
  "Can the Pinnacle Number be used for personal growth?";
export const PINNACLE_FAQ_ANSWER_9 =
  "Yes, understanding your Pinnacle Number can help you identify your life purpose, spiritual potential, and areas for personal growth.";

export const PINNACLE_FAQ_QUESTION_10 =
  "Is there a cost to use the Pinnacle Number Calculator?";
export const PINNACLE_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same ";

export const BALANCE_FAQ_QUESTION_1 = "What is a Balance Number?";
export const BALANCE_FAQ_ANSWER_1 =
  "A Balance Number is a numerological value that represents a person's ability to balance different aspects of their life.";

export const BALANCE_FAQ_QUESTION_2 = "What does the Balance Number reveal?";
export const BALANCE_FAQ_ANSWER_2 =
  "The Balance Number reveals a person's ability to balance their physical, emotional, mental, and spiritual energies.";

export const BALANCE_FAQ_QUESTION_3 =
  "How does the Balance Number impact a person's life?";
export const BALANCE_FAQ_ANSWER_3 =
  "The Balance Number influences a person's relationships, career, and overall well-being, guiding them towards harmony and stability.";

export const BALANCE_FAQ_QUESTION_4 = "Can the Balance Number change?";
export const BALANCE_FAQ_ANSWER_4 =
  "No, the Balance Number remains the same throughout a person's life, as it's based on their birth date.";

export const BALANCE_FAQ_QUESTION_5 =
  "Is the Balance Number related to a person's personality?";
export const BALANCE_FAQ_ANSWER_5 =
  "Yes, the Balance Number is related to a person's personality traits, such as their level of emotional stability, mental clarity, and physical vitality.";

export const BALANCE_FAQ_QUESTION_6 =
  "Can the Balance Number be used for personal growth?";
export const BALANCE_FAQ_ANSWER_6 =
  "Yes, understanding your Balance Number can help you identify areas for personal growth and development, leading to greater balance and harmony in your life.";

export const BALANCE_FAQ_QUESTION_7 =
  "What is the difference between Balance Number and Life Path Number?";
export const BALANCE_FAQ_ANSWER_7 =
  "The Balance Number focuses on balance and harmony, while the Life Path Number focuses on life purpose and spiritual journey.";

export const BALANCE_FAQ_QUESTION_8 =
  "How does the Balance Number relate to spiritual growth?";
export const BALANCE_FAQ_ANSWER_8 =
  "The Balance Number reveals a person's potential for spiritual growth and self-awareness, guiding them towards inner peace and enlightenment.";

export const BALANCE_FAQ_QUESTION_9 =
  "Can the Balance Number be used for self-reflection?"; // Initialize as empty string
export const BALANCE_FAQ_ANSWER_9 =
  "Yes, the Balance Number provides insights into your strengths and weaknesses, helping you reflect on areas where you need to achieve balance and harmony."; // Initialize as empty string

export const BALANCE_FAQ_QUESTION_10 =
  "Is there a cost to use the Balance Number Calculator?"; // Initialize as empty string
export const BALANCE_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same "; // Initialize as empty string

export const PASSION_FAQ_QUESTION_1 = "What is the Hidden Passion Number?";
export const PASSION_FAQ_ANSWER_1 =
  "The Hidden Passion Number in numerology reflects deep-seated interests, motivations, and talents that may not always be obvious to others. It reveals a core aspect of your personality that drives your inner desires and passions.";

export const PASSION_FAQ_QUESTION_2 =
  "How is the Hidden Passion Number calculated?";
export const PASSION_FAQ_ANSWER_2 =
  "The Hidden Passion Number is calculated using the numerical values of your full birth name, applying specific numerological calculations to uncover your deepest passions.";

export const PASSION_FAQ_QUESTION_3 =
  "What does my Hidden Passion Number reveal about me?";
export const PASSION_FAQ_ANSWER_3 =
  "Your Hidden Passion Number unveils specific interests, talents, and motivations that resonate deeply within you. It can indicate areas where you find fulfillment and joy, even if these passions are not immediately apparent in your daily life.";

export const PASSION_FAQ_QUESTION_4 =
  "Can the Hidden Passion Number change over time?";
export const PASSION_FAQ_ANSWER_4 =
  "Your Hidden Passion Number is typically derived from your birth name and remains relatively stable throughout life. However, personal growth and life experiences can influence how these passions manifest or evolve.";

export const PASSION_FAQ_QUESTION_5 =
  "Is the Hidden Passion Number as important as other numerology numbers like Life Path or Destiny Number?";
export const PASSION_FAQ_ANSWER_5 =
  "While the Life Path and Destiny Numbers often receive more attention, the Hidden Passion Number offers valuable insights into your core interests and motivations. It complements other numerology numbers by providing a deeper understanding of your inner drives.";

export const PASSION_FAQ_QUESTION_6 =
  "How can knowing my Hidden Passion Number benefit me?";
export const PASSION_FAQ_ANSWER_6 =
  "Understanding your Hidden Passion Number can help you align your life choices with your true desires and talents. It provides clarity on areas where you can excel and find fulfillment, guiding decisions related to career, hobbies, and personal development.";

export const PASSION_FAQ_QUESTION_7 =
  "What if I have multiple names or nicknames? Which name should I use for calculating the Hidden Passion Number?";
export const PASSION_FAQ_ANSWER_7 =
  "It is best to use the name you were given at birth.";

export const PASSION_FAQ_QUESTION_8 =
  "Can I change my Hidden Passion Number by changing my name or nickname?";
export const PASSION_FAQ_ANSWER_8 =
  "Yes, changing your name can alter certain aspects of your numerology profile, including the Hidden Passion Number. However, it's important to consider name changes carefully as they can impact various aspects of your life beyond numerology readings.";

export const PASSION_FAQ_QUESTION_9 =
  "Do you offer personalized consultations to interpret my Hidden Passion Number?"; // Initialize as empty string
export const PASSION_FAQ_ANSWER_9 =
  "We have Provided a general Interpreation based on Hidden passion Number free of cost to our user , however if you have any other Questions related to Numerology we do provide one -one Consulation please check our Services page for the same  "; // Initialize as empty string

export const PASSION_FAQ_QUESTION_10 =
  "Is there a cost to use the Balance Number Calculator?"; // Initialize as empty string
export const PASSION_FAQ_ANSWER_10 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same."; // Initialize as empty string

export const SUBCONSCIOUS_FAQ_QUESTION_1 =
  "Is there a cost to use the Subconscious Self  Number Calculator?";
export const SUBCONSCIOUS_FAQ_ANSWER_1 =
  "Above Tool is Free to Use with a Predefined Rate Limit . However if you required one on one Consultation that is Paid . You can Check our Services Page for the same ";

export const SUBCONSCIOUS_FAQ_QUESTION_2 =
  "How is the Subconscious Self Number calculated?";
export const SUBCONSCIOUS_FAQ_ANSWER_2 =
  "The Subconscious Self Number is calculated using the numerical values of your birthdate, applying specific numerological calculations to uncover hidden aspects of your personality.";

export const SUBCONSCIOUS_FAQ_QUESTION_3 =
  "What is the Subconscious Self Number?";
export const SUBCONSCIOUS_FAQ_ANSWER_3 =
  "The Subconscious Self Number in numerology reveals hidden desires, fears, and aspects of your personality that influence your subconscious motivations and behavior patterns.";

export const SUBCONSCIOUS_FAQ_QUESTION_4 =
  "What does my Subconscious Self Number reveal about me?";
export const SUBCONSCIOUS_FAQ_ANSWER_4 =
  "Your Subconscious Self Number uncovers deep-seated feelings, desires, and tendencies that operate beneath your conscious awareness. It sheds light on aspects of your personality that may influence your decisions and relationships.";

export const SUBCONSCIOUS_FAQ_QUESTION_5 =
  "Can the Subconscious Self Number change over time?";
export const SUBCONSCIOUS_FAQ_ANSWER_5 =
  "Like other core numerology numbers derived from your birth name, the Subconscious Self Number remains relatively stable throughout your life. However, personal growth and experiences can lead to shifts in how these subconscious traits manifest.";

export const SUBCONSCIOUS_FAQ_QUESTION_6 =
  "How does the Subconscious Self Number differ from other numerology numbers like Life Path or Destiny Number?";
export const SUBCONSCIOUS_FAQ_ANSWER_6 =
  "While the Life Path and Destiny Numbers focus on your external path and life purpose, the Subconscious Self Number delves into your inner psyche and hidden motivations. It provides insights into your emotional landscape and subconscious influences.";

export const SUBCONSCIOUS_FAQ_QUESTION_7 =
  "How can knowing my Subconscious Self Number benefit me?";
export const SUBCONSCIOUS_FAQ_ANSWER_7 =
  "Understanding your Subconscious Self Number can enhance self-awareness and help you recognize unconscious patterns that affect your relationships, career choices, and personal development. It empowers you to align your actions with your deeper desires.";

export const SUBCONSCIOUS_FAQ_QUESTION_8 =
  "Are there specific traits associated with different Subconscious Self Numbers?";
export const SUBCONSCIOUS_FAQ_ANSWER_8 =
  "Yes, each Subconscious Self Number carries distinct traits and tendencies. please use the tool to get your , Friends or Family member Number and its interpretation ";

export const SUBCONSCIOUS_FAQ_QUESTION_9 =
  "Can I use my nickname or a shortened version of my name to calculate the Subconscious Self Number?"; // Initialize as empty string
export const SUBCONSCIOUS_FAQ_ANSWER_9 =
  "For numerology calculations, it's recommended to use your full birth name as it appears on legal documents for accuracy. Variations in names can reveal different aspects, but the birth name provides a foundational reading."; // Initialize as empty string

export const SUBCONSCIOUS_FAQ_QUESTION_10 =
  "Do you offer personalized consultations to interpret my Subconscious Self Number?"; // Initialize as empty string
export const SUBCONSCIOUS_FAQ_ANSWER_10 =
  "We have Provided a general Interpretation based on your Subconscious  Number free of cost , however if you have any other questions related to Numerology we do provide one consulation - please check our Services page for the same."; // Initialize as empty string

export const KARMIC_FAQ_QUESTION_1 =
  "What is a Karmic Debt Number in numerology?";
export const KARMIC_FAQ_ANSWER_1 =
  "A Karmic Debt Number in numerology indicates unresolved issues or challenges from past lives that manifest as obstacles in the present life, requiring acknowledgment and resolution.";

export const KARMIC_FAQ_QUESTION_2 =
  "How is the Karmic Debt Number calculated?";
export const KARMIC_FAQ_ANSWER_2 =
  "The Karmic Debt Number is calculated using the numerical values of your birthdate, applying specific numerological calculations to identify any karmic debts associated with past actions.";

export const KARMIC_FAQ_QUESTION_3 = "What does a Karmic Debt Number indicate?";
export const KARMIC_FAQ_ANSWER_3 =
  "A Karmic Debt Number indicates areas of life where you may face repeated challenges or setbacks due to unresolved karmic issues that need to be addressed.";

export const KARMIC_FAQ_QUESTION_4 = "Are Karmic Debt Numbers always negative?";
export const KARMIC_FAQ_ANSWER_4 =
  "No, while Karmic Debt Numbers highlight challenges, they also provide opportunities for growth and spiritual development by addressing past actions and resolving karmic debts.";

export const KARMIC_FAQ_QUESTION_5 = "Can the Karmic Debt Number change?";
export const KARMIC_FAQ_ANSWER_5 =
  "The Karmic Debt Number remains constant throughout your life as it is derived from your birthdate and reflects enduring lessons and challenges that need to be acknowledged and resolved.";

export const KARMIC_FAQ_QUESTION_6 =
  "Is the Karmic Debt Number related to astrology?";
export const KARMIC_FAQ_ANSWER_6 =
  "No, the Karmic Debt Number is a concept specific to numerology and does not correlate with astrological signs or charts.";

export const KARMIC_FAQ_QUESTION_7 =
  "How can I identify my Karmic Debt Number?";
export const KARMIC_FAQ_ANSWER_7 =
  "You can identify your Karmic Debt Number by calculating it based on the numerical values assigned to each letter in your birthdate, using established numerological methods.";

export const KARMIC_FAQ_QUESTION_8 =
  "What if I have multiple Karmic Debt Numbers?";
export const KARMIC_FAQ_ANSWER_8 =
  "Typically, individuals have one or two Karmic Debt Numbers, indicating specific areas of life where karmic lessons are prominent.";

export const KARMIC_FAQ_QUESTION_9 = ""; // Initialize as empty string
export const KARMIC_FAQ_ANSWER_9 = ""; // Initialize as empty string

export const KARMIC_FAQ_QUESTION_10 = ""; // Initialize as empty string
export const KARMIC_FAQ_ANSWER_10 = ""; // Initialize as empty string

export const DAILY_BIORHYTHM_FAQ_QUESTION_1 =
  "What is a Daily Biorhythm Chart in numerology?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_1 =
  "A Daily Biorhythm Chart in numerology shows the cyclical rhythms of various aspects of your life, such as physical, emotional, and intellectual energies, based on your birthdate.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_2 =
  "How is a Daily Biorhythm Chart calculated?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_2 =
  "A Daily Biorhythm Chart is calculated using your birthdate to determine your physical, emotional, and intellectual cycles for any given day.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_3 =
  "What do the different cycles on a Daily Biorhythm Chart represent?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_3 =
  "The physical cycle represents vitality and physical well-being. The emotional cycle indicates mood and emotional stability. The intellectual cycle reflects mental alertness and cognitive abilities.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_4 =
  "How can I use a Daily Biorhythm Chart?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_4 =
  "You can use a Daily Biorhythm Chart to plan activities and understand your natural rhythms. It helps in scheduling tasks when you are likely to be at peak performance in each area.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_5 =
  "Is a Daily Biorhythm Chart accurate?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_5 =
  "The accuracy of a Daily Biorhythm Chart is debated. Some find it insightful for personal planning, while others consider it pseudoscience.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_6 =
  "Can Daily Biorhythm Charts predict future events?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_6 =
  "No, Daily Biorhythm Charts do not predict future events but rather indicate cyclic patterns in physiological, emotional, and intellectual energies.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_7 =
  "How often should I check my Daily Biorhythm Chart?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_7 =
  "You can check your Daily Biorhythm Chart daily or periodically to understand your current physical, emotional, and intellectual states.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_8 = "Can I improve my biorhythms?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_8 =
  "While biorhythms are considered innate cycles, maintaining a healthy lifestyle can support overall well-being and potentially influence daily fluctuations.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_9 =
  "What if my biorhythms are out of sync?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_9 =
  "Biorhythms are cyclic and naturally fluctuate. Focus on self-care and adapting activities based on your current biorhythm states.";

export const DAILY_BIORHYTHM_FAQ_QUESTION_10 =
  "Are Daily Biorhythm Charts used in medical practices?";
export const DAILY_BIORHYTHM_FAQ_ANSWER_10 =
  "No, Daily Biorhythm Charts are not recognized in medical practices as predictors or indicators of health conditions.";

export const NAME_FAQ_QUESTION_1 = "What is a Name Number in numerology?";
export const NAME_FAQ_ANSWER_1 =
  "A Name Number in numerology is derived from the numerical values assigned to letters in your full name. It is believed to reveal aspects of your personality, strengths, and challenges.";

export const NAME_FAQ_QUESTION_2 = "How is a Name Number calculated?";
export const NAME_FAQ_ANSWER_2 =
  "A Name Number is calculated by assigning numerical values to each letter in your full name, summing them up, and reducing them to a single-digit or master number.";

export const NAME_FAQ_QUESTION_3 = "What does a Name Number indicate?";
export const NAME_FAQ_ANSWER_3 =
  "A Name Number indicates your innate talents, characteristics, and potential in numerology. It reflects how you present yourself to the world and influences your interactions.";

export const NAME_FAQ_QUESTION_4 =
  "Can changing my name change my Name Number?";
export const NAME_FAQ_ANSWER_4 =
  "Yes, changing your name can change your Name Number, potentially altering the numerological insights derived from your previous name.";

export const NAME_FAQ_QUESTION_5 =
  "Is a Name Number the same as a Destiny Number?";
export const NAME_FAQ_ANSWER_5 =
  "No, a Name Number and a Destiny Number are different. A Name Number is derived from your full name, while a Destiny Number is derived from your birthdate.";

export const NAME_FAQ_QUESTION_6 =
  "How does a Name Number affect relationships?";
export const NAME_FAQ_ANSWER_6 =
  "A Name Number can influence compatibility and interactions in relationships based on numerological interpretations of personality traits.";

export const NAME_FAQ_QUESTION_7 = "Can I have multiple Name Numbers?";
export const NAME_FAQ_ANSWER_7 =
  "No, typically, you have one Name Number derived from your full name. However, variations in name spellings may result in different calculations.";

export const NAME_FAQ_QUESTION_8 =
  "What if my name has special characters or accents?";
export const NAME_FAQ_ANSWER_8 =
  "In numerology, special characters or accents may have specific numerical values assigned to them for calculation purposes.";

export const NAME_FAQ_QUESTION_9 =
  "Is the Name Number based on my first name or full name?";
export const NAME_FAQ_ANSWER_9 =
  "The Name Number is based on your full name, including all given names and surname.";

export const NAME_FAQ_QUESTION_10 = "Can a Name Number change over time?";
export const NAME_FAQ_ANSWER_10 =
  "Yes, if you legally change your name or if your name spelling is altered, your Name Number may change.";

export const PERSONALITY_FAQ_QUESTION_1 =
  "What is a Personality Number in numerology?";
export const PERSONALITY_FAQ_ANSWER_1 =
  "A Personality Number in numerology is derived from the consonants in your full birth name. It is believed to reveal aspects of your personality traits and characteristics.";

export const PERSONALITY_FAQ_QUESTION_2 =
  "How is a Personality Number calculated?";
export const PERSONALITY_FAQ_ANSWER_2 =
  "A Personality Number is calculated by assigning numerical values to the consonants in your full birth name, summing them up, and reducing them to a single-digit or master number.";

export const PERSONALITY_FAQ_QUESTION_3 =
  "What does a Personality Number indicate?";
export const PERSONALITY_FAQ_ANSWER_3 =
  "A Personality Number indicates your natural traits, behaviors, and tendencies in numerology. It reflects how you perceive yourself and how others perceive you.";

export const PERSONALITY_FAQ_QUESTION_4 =
  "Can changing my name change my Personality Number?";
export const PERSONALITY_FAQ_ANSWER_4 =
  "Yes, changing your name can change your Personality Number, potentially altering the numerological insights derived from your previous name.";

export const PERSONALITY_FAQ_QUESTION_5 =
  "Is a Personality Number the same as a Destiny Number?";
export const PERSONALITY_FAQ_ANSWER_5 =
  "No, a Personality Number and a Destiny Number are different. A Personality Number focuses on traits derived from consonants, while a Destiny Number focuses on traits derived from your birthdate.";

export const PERSONALITY_FAQ_QUESTION_6 =
  "How does a Personality Number affect relationships?";
export const PERSONALITY_FAQ_ANSWER_6 =
  "A Personality Number can influence compatibility and interactions in relationships based on numerological interpretations of personality traits.";

export const PERSONALITY_FAQ_QUESTION_7 =
  "Can I have multiple Personality Numbers?";
export const PERSONALITY_FAQ_ANSWER_7 =
  "No, typically, you have one Personality Number derived from your full birth name. Variations in name spellings may result in different calculations.";

export const PERSONALITY_FAQ_QUESTION_8 =
  "What if my name has special characters or accents?";
export const PERSONALITY_FAQ_ANSWER_8 =
  "In numerology, special characters or accents may have specific numerical values assigned to them for calculation purposes.";

export const PERSONALITY_FAQ_QUESTION_9 =
  "Is the Personality Number based on my first name or full name?";
export const PERSONALITY_FAQ_ANSWER_9 =
  "The Personality Number is based on your full birth name, including all given names and surname.";

export const PERSONALITY_FAQ_QUESTION_10 =
  "Can a Personality Number change over time?";
export const PERSONALITY_FAQ_ANSWER_10 =
  "Yes, if you legally change your name or if your name spelling is altered, your Personality Number may change.";

export const JERSEY_FAQ_QUESTION_1 =
  "What is a Lucky Jersey Number in numerology?";
export const JERSEY_FAQ_ANSWER_1 =
  "A Lucky Jersey Number is a number believed to bring luck and positive energies when associated with a jersey or clothing, based on numerological calculations.";

export const JERSEY_FAQ_QUESTION_2 = "How do I choose my Lucky Jersey Number?";
export const JERSEY_FAQ_ANSWER_2 =
  "To choose your Lucky Jersey Number, you can consider numbers associated with your birthdate, life path number, or other significant numerological influences.";

export const JERSEY_FAQ_QUESTION_3 = "Can my Lucky Jersey Number change?";
export const JERSEY_FAQ_ANSWER_3 =
  "Yes, your Lucky Jersey Number can change based on your evolving numerological influences or personal preferences.";

export const JERSEY_FAQ_QUESTION_4 =
  "Are there specific numbers considered universally lucky?";
export const JERSEY_FAQ_ANSWER_4 =
  "Yes, certain numbers like 7, 9, and 11 are often considered universally lucky in various cultures and numerological systems.";

export const JERSEY_FAQ_QUESTION_5 =
  "Can a Lucky Jersey Number affect performance?";
export const JERSEY_FAQ_ANSWER_5 =
  "Belief in a Lucky Jersey Number can positively impact performance by boosting confidence and focusing positive energies.";

export const JERSEY_FAQ_QUESTION_6 =
  "Is there a numerological significance to jersey numbers in sports?";
export const JERSEY_FAQ_ANSWER_6 =
  "Yes, jersey numbers in sports can carry numerological meanings, influencing player dynamics, team unity, and fan perception.";

export const JERSEY_FAQ_QUESTION_7 =
  "Can I have multiple Lucky Jersey Numbers?";
export const JERSEY_FAQ_ANSWER_7 =
  "While you may have preferences for multiple numbers, typically, individuals associate with one primary Lucky Jersey Number at a time.";

export const JERSEY_FAQ_QUESTION_8 =
  "Should I choose a jersey number based on my position in sports?";
export const JERSEY_FAQ_ANSWER_8 =
  "Choosing a jersey number based on your position or role in sports can add personal significance and align with team traditions or preferences.";

export const JERSEY_FAQ_QUESTION_9 =
  "What if my preferred jersey number is already taken?";
export const JERSEY_FAQ_ANSWER_9 =
  "If your preferred jersey number is unavailable, consider alternative numbers with personal or numerological significance.";

export const JERSEY_FAQ_QUESTION_10 =
  "How does jersey number superstition affect athletes?";
export const JERSEY_FAQ_ANSWER_10 =
  "Superstitions regarding jersey numbers can influence athlete behavior, team dynamics, and fan interactions, contributing to mental focus and performance.";

//=========================================================================================================================================================================================================================//

// TERMS AND CONDITIONS

export const termsHeading = "Terms and Conditions";
export const termsIntroductionHeading = "1. Introduction";
export const termsUseOfWebsiteHeading = "2. Use of the Website";
export const termsEligibilitySubheading = "Eligibility";
export const termsAccountResponsibilitySubheading = "Account Responsibility";
export const termsProhibitedActivitiesSubheading = "Prohibited Activities";
export const termsServicesProvidedHeading = "3. Services Provided";
export const termsDescriptionOfServicesSubheading = "Description of Services";
export const termsAccuracyOfInformationSubheading = "Accuracy of Information";
export const termsIntellectualPropertyHeading = "4. Intellectual Property";
export const termsContentOwnershipSubheading = "Content Ownership";
export const termsUserGeneratedContentSubheading = "User-Generated Content";
export const termsPrivacyPolicyHeading = "5. Privacy Policy";
export const termsDataCollectionSubheading = "Data Collection";
export const termsCookiesSubheading = "Cookies";
export const termsPaymentAndRefundsHeading = "6. Payment and Refunds";
export const termsPaymentTermsSubheading = "Payment Terms";
export const termsRefundPolicySubheading = "Refund Policy";
export const termsDisclaimersAndLiabilityHeading =
  "7. Disclaimers and Limitation of Liability";
export const termsNoProfessionalAdviceSubheading = "No Professional Advice";
export const termsLimitationOfLiabilitySubheading = "Limitation of Liability";
export const termsUserConductHeading = "8. User Conduct";
export const termsUserObligationsSubheading = "User Obligations";
export const termsTerminationOfAccessSubheading = "Termination of Access";
export const termsChangesToTermsHeading = "9. Changes to Terms";
export const termsModificationOfTermsSubheading = "Modification of Terms";
export const termsGoverningLawHeading = "10. Governing Law";
export const termsJurisdictionSubheading = "Jurisdiction";
export const termsContactInformationHeading = "11. Contact Information";
export const termsCustomerSupportSubheading = "Customer Support";

export const termsIntroduction = `
Welcome to EliteNumerology.com. These terms and conditions ("Terms") govern your use of our Website and the services provided by us. By accessing or using our Website, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our Website.
`;

export const termsUseEligibility = `
You must be at least 18 years old to use this Website. By using our Website, you represent and warrant that you meet this eligibility requirement.
`;

export const termsAccountResponsibility = `
You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
`;

export const termsProhibitedActivities = `
You agree not to engage in any of the following activities:
• Using the Website for any illegal purposes or in violation of any applicable laws.
• Spamming, phishing, or distributing malware.
• Interfering with or disrupting the functionality of the Website.
• Attempting to gain unauthorized access to any part of the Website or its systems.
`;

export const termsDescriptionOfServices = `
Our Website offers various numerology-related services, including but not limited to numerology calculators, reports, and consultations. These services are provided for informational purposes only.
`;

export const termsAccuracyOfInformation = `
While we strive to provide accurate and up-to-date information, we do not guarantee the accuracy, completeness, or reliability of any numerological interpretations or advice provided on our Website.
`;

export const termsContentOwnership = `
All content on the Website, including text, graphics, logos, images, and software, is the property of EliteNumerology.com and is protected by intellectual property laws. Unauthorized use of any content is strictly prohibited.
`;

export const termsUserGeneratedContent = `
Users may upload content, such as comments and reviews, to the Website. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and display the content on the Website.
`;

export const termsDataCollection = `
We collect personal information, such as your name, birthdate, and email address, to provide our services. For more details on our data collection practices, please refer to our Privacy Policy.
`;

export const termsCookies = `
Our Website uses cookies to enhance your user experience. By using our Website, you consent to the use of cookies in accordance with our Cookie Policy.
`;

export const termsPaymentTerms = `
Payments for our services can be made using various payment methods, including UPI, credit/debit cards, and PayPal. All payments are processed securely.
`;

export const termsRefundPolicy = `
We offer refunds under specific conditions. Please review our Refund Policy for detailed information on when and how refunds are provided.
`;

export const termsNoProfessionalAdvice = `
The content and services provided on our Website are for informational purposes only and are not a substitute for professional advice. You should seek the advice of a qualified professional before making any decisions based on the information provided.
`;

export const termsLimitationOfLiability = `
To the fullest extent permitted by law, EliteNumerology.com shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use of our Website or services.
`;

export const termsUserObligations = `
You agree to use our Website responsibly and in compliance with all applicable laws and regulations. You are solely responsible for any content you upload or share on the Website.
`;

export const termsTerminationOfAccess = `
We reserve the right to terminate or suspend your access to the Website for any reason, including violation of these Terms.
`;

export const termsModificationOfTerms = `
We may modify these Terms at any time. Any changes will be effective immediately upon posting on the Website. It is your responsibility to review the Terms periodically for any updates.
`;

export const termsJurisdiction = `
These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].
`;

export const termsCustomerSupport = `
If you have any questions or concerns about these Terms, please contact us at contact@EliteNumerology.com
`;
