import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const PopupForm = ({ isVisible, onClose, userData, setUserData }) => {
  // Initialize state variables
  const [formData, setFormData] = useState({
    fullName: "",
    day: "",
    month: "",
    year: "",
  });
  const [buttonHover, setButtonHover] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const popupRef = useRef(null);
  const location = useLocation();

  // Check if current route is a footer page or home page
  const isFooterPage =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/contact-us" ||
    location.pathname === "/terms-and-conditions" ||
    location.pathname === "/faq";

  // Effect to update form data when userData changes
  useEffect(() => {
    if (isVisible && userData) {
      setFormData({
        fullName: userData.fullName || "",
        day: userData.dateOfBirth ? userData.dateOfBirth.split("-")[2] : "",
        month: userData.dateOfBirth ? userData.dateOfBirth.split("-")[1] : "",
        year: userData.dateOfBirth ? userData.dateOfBirth.split("-")[0] : "",
      });
    }
  }, [isVisible, userData]);

  // Effect to handle popup visibility based on local storage
  useEffect(() => {
    const popupShown = localStorage.getItem("popupShown");

    if (!popupShown) {
      if (isVisible) {
        localStorage.setItem("popupShown", "true");
      }
    }
  }, [isVisible]);

  // Effect to handle click outside the popup to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  // Function to handle form input changes
  const handleChange = (e) => {
    setShowErrorMessage(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const { fullName, day, month, year } = formData;
    if (!fullName || !day || !month || !year) {
      setShowErrorMessage(true);
      return;
    }
    const dateOfBirth = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    const updatedUserData = {
      ...formData,
      fullName,
      dateOfBirth,
    };
    localStorage.setItem("userData", JSON.stringify(updatedUserData));
    setUserData(updatedUserData);
    onClose(updatedUserData);
  };

  // Function to handle popup close
  const handleClose = () => {
    onClose();
  };

  // Month names array
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Render the component
  return (
    <>
      {isVisible && !isFooterPage && (
        <div style={styles.overlay}>
          <div ref={popupRef} style={styles.popup}>
            <button onClick={handleClose} style={styles.closeButtonTopRight}>
              ×
            </button>
            <h2 style={styles.title}>
              Do you wish to submit data for Pre-Filling forms on all our Tools?
            </h2>
            <form onSubmit={handleSubmit}>
              {showErrorMessage && (
                <div style={styles.errorMessage}>
                  Please fill both name and date of birth. If you don't wish to,
                  choose to skip. No data will be saved if you skip.
                </div>
              )}
              <label style={styles.label}>
                Full Name:
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
              </label>
              <label style={styles.label}>
                Date of Birth:
                <div style={styles.dateInputs}>
                  <select
                    name="day"
                    value={formData.day}
                    onChange={handleChange}
                    style={styles.select}
                  >
                    <option value="">Day</option>
                    {Array.from({ length: 31 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  <select
                    name="month"
                    value={formData.month}
                    onChange={handleChange}
                    style={styles.select}
                  >
                    <option value="">Month</option>
                    {monthNames.map((monthName, index) => (
                      <option key={index + 1} value={index + 1}>
                        {monthName}
                      </option>
                    ))}
                  </select>
                  <select
                    name="year"
                    value={formData.year}
                    onChange={handleChange}
                    style={styles.select}
                  >
                    <option value="">Year</option>
                    {Array.from({ length: 100 }, (_, i) => (
                      <option key={i + 1923} value={i + 1923}>
                        {i + 1923}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
              <div style={styles.buttonContainer}>
                <button
                  type="submit"
                  style={{
                    ...styles.button,
                    backgroundColor: buttonHover ? "orange" : "#FFD700",
                  }}
                  onMouseEnter={() => setButtonHover(true)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleClose}
                  style={styles.skipButton}
                >
                  Skip
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

// Styles object for CSS styles
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
  },
  popup: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    maxWidth: "500px",
    width: "90%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
    position: "relative",
  },
  closeButtonTopRight: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "40px",
    lineHeight: "16px",
    width: "20px",
    height: "20px",
    padding: "0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  title: {
    color: "black",
    textAlign: "center",
    marginBottom: "20px",
  },
  label: {
    display: "block",
    color: "black",
    marginBottom: "10px",
  },
  dateInputs: {
    display: "flex",
    justifyContent: "space-between",
  },
  select: {
    width: "30%",
    padding: "8px",
    margin: "0 2px",
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
  },
  skipButton: {
    marginTop: "10px",
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "lightgray",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    marginBottom: "10px",
  },
};

export default PopupForm;
