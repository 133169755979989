import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./PersonalYear.css";
import { API_BASE_URL } from "../components/Constants";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { personalYearInstructions } from "./PersonalYear_How_to";
import { Helmet } from "react-helmet";
import {
  PERSONAL_YEAR_FAQ_QUESTION_1,
  PERSONAL_YEAR_FAQ_ANSWER_1,
  PERSONAL_YEAR_FAQ_QUESTION_2,
  PERSONAL_YEAR_FAQ_ANSWER_2,
  PERSONAL_YEAR_FAQ_QUESTION_3,
  PERSONAL_YEAR_FAQ_ANSWER_3,
  PERSONAL_YEAR_FAQ_QUESTION_4,
  PERSONAL_YEAR_FAQ_ANSWER_4,
  PERSONAL_YEAR_FAQ_QUESTION_5,
  PERSONAL_YEAR_FAQ_ANSWER_5,
  PERSONAL_YEAR_FAQ_QUESTION_6,
  PERSONAL_YEAR_FAQ_ANSWER_6,
  PERSONAL_YEAR_FAQ_QUESTION_7,
  PERSONAL_YEAR_FAQ_ANSWER_7,
  PERSONAL_YEAR_FAQ_QUESTION_8,
  PERSONAL_YEAR_FAQ_ANSWER_8,
  PERSONAL_YEAR_FAQ_QUESTION_9,
  PERSONAL_YEAR_FAQ_ANSWER_9,
  PERSONAL_YEAR_FAQ_QUESTION_10,
  PERSONAL_YEAR_FAQ_ANSWER_10,
} from "../components/Constants";
import ExampleUI from "../components/ExampleUI";
import exampleTextsPersonalYear from "./PersonalYear_Example_Text";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_1,
      answer: PERSONAL_YEAR_FAQ_ANSWER_1,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_2,
      answer: PERSONAL_YEAR_FAQ_ANSWER_2,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_3,
      answer: PERSONAL_YEAR_FAQ_ANSWER_3,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_4,
      answer: PERSONAL_YEAR_FAQ_ANSWER_4,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_5,
      answer: PERSONAL_YEAR_FAQ_ANSWER_5,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_6,
      answer: PERSONAL_YEAR_FAQ_ANSWER_6,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_7,
      answer: PERSONAL_YEAR_FAQ_ANSWER_7,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_8,
      answer: PERSONAL_YEAR_FAQ_ANSWER_8,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_9,
      answer: PERSONAL_YEAR_FAQ_ANSWER_9,
    },
    {
      question: PERSONAL_YEAR_FAQ_QUESTION_10,
      answer: PERSONAL_YEAR_FAQ_ANSWER_10,
    },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function PersonalYear({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const [, setInputValue] = useState("");
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const url = window.location.href;
  const mouseTitle = "Find your Personal Year Number";
  const browserTitle = "Personal Year Number";
  const endpoint = "persnolYearCalculator"; // Example endpoint, adjust as needed
  const [, setError] = useState(null);
  const [day, setDay] = useState(userData?.day || 1);
  const [month, setMonth] = useState(userData?.month || 1);
  const [yearOfBirth, setYearOfBirth] = useState(userData?.year || 1900);
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [personalYearNumber, setPersonalYearNumber] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [yearPredictionCharacteristics, setYearPredictionCharacteristics] =
    useState("");
  const handleDayChange = (e) => setDay(parseInt(e.target.value, 10));
  const handleMonthChange = (e) => setMonth(parseInt(e.target.value, 10));
  const handleYearChange = (e) => setYearOfBirth(parseInt(e.target.value, 10));

  const [yearPredictionAdvice, setYearPredictionAdvice] = useState("");
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const shareText =
    "Hi, I found my Complete Monthwise Report for the year,  Find out your's at";
  const hasContent = personalYearNumber !== null || apiError;

  useEffect(() => {
    setPersonalYearNumber(null);
    setPredictions([]);
    setYearPredictionCharacteristics("");
    setYearPredictionAdvice("");
    setApiError(null);
  }, [day, month, yearOfBirth]);

  useEffect(() => {
    if (userData) {
      setDay(userData.day || 1);
      setMonth(userData.month || 1);
      setYearOfBirth(userData.year || 1900);
    }
  }, [userData]);

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, yearOfBirth);
  }, [month, yearOfBirth, day]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    setPersonalYearNumber(null);
    setPredictions([]);
    setYearPredictionCharacteristics("");
    setYearPredictionAdvice("");
    setApiError(null);
    setLoading(true);
    const startTime = Date.now(); // Record the start time

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      setLoading(false);
      return;
    }

    const formattedDateOfBirth = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${yearOfBirth}`;
    const apiUrl = `${API_BASE_URL}${endpoint}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateOfBirth: formattedDateOfBirth,
          predictionYear: new Date().getFullYear().toString(),
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime; // Ensure a minimum of 5 seconds

      setTimeout(
        () => {
          setPersonalYearNumber(data.persnol_year_Number);
          setPredictions(data.months);
          setYearPredictionCharacteristics(
            data.yearPrediction_Characterstic_Focus
          );
          setYearPredictionAdvice(data.yearPrediction_Advice);
          setApiError(null);

          // Update user data with edited values
          setUserData({
            ...userData,
            day: day,
            month: month,
            year: yearOfBirth,
          });

          setLoading(false);
        },
        remainingTime > 0 ? remainingTime : 0
      );
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      setLoading(false);
      setApiError("Failed to connect. Please try again later.");
    } finally {
      // Update loading state and decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="PersonalYear">
      <Helmet>
        <title>Personal Year Number</title>
        <meta
          name="description"
          content="Find your Personal Year Number to understand the themes and energies influencing your current year for better decision-making."
        />
      </Helmet>
      <div className="content">
        <div className="input-and-result-window">
          <div className="special">
            <h1>Personal Year Number Calculator</h1>
            <div>
              <p className="label">Select your Date of Birth:</p>
              <select value={day} onChange={handleDayChange}>
                <option value="" disabled selected>
                  Day
                </option>
                {Array.from({ length: daysInMonth }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <select value={month} onChange={handleMonthChange}>
                <option value="" disabled selected>
                  Month
                </option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {new Date(0, i + 1, 0).toLocaleString("en", {
                      month: "long",
                    })}
                  </option>
                ))}
              </select>
              <select value={yearOfBirth} onChange={handleYearChange}>
                <option value="" disabled selected>
                  Year
                </option>
                {Array.from({ length: 2024 - 1900 }, (_, i) => (
                  <option key={i + 1900} value={i + 1900}>
                    {i + 1900}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              <b>Personal Year Number: </b>
              {personalYearNumber}
            </p>

            <h4>Focus: </h4>
            {yearPredictionCharacteristics}

            <h4>Advice: </h4>
            {yearPredictionAdvice}
          </div>
        </div>
        <div></div>
        {/* Loader component is displayed when loading is true */}
        {loading && <Loader />}
        {apiError && (
          <div>
            <p>Error: {apiError}</p>
          </div>
        )}
        <div
          className={`left-align-content result-ui ${
            hasContent ? "filled" : ""
          }`}
          id="breadcrumbs"
        >
          {predictions.length > 0 && (
            <div>
              <h2>Monthly Predictions : </h2>
              <div className="monthly-predictions">
                {predictions.map((month, index) => (
                  <div key={index} className="month-box">
                    <h3 className="month-name">{month.monthName}</h3>
                    <hr className="month-hr"></hr>
                    <p className="month-prediction">{month.monthPrediction}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate Personal Year : </h2>
          {personalYearInstructions.howToCalculate}
        </div>
        <ExampleUI texts={exampleTextsPersonalYear} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default PersonalYear;
