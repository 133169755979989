const exampleTextsHouseNumerology = {
  step1:
    "Write Down Your Date of Birth and Complete House Number: Date of Birth: 07/07/1981; House Number: C410",
  step2:
    "Calculate Your Life Path Number: Add all digits together: 0 + 7 + 0 + 7 + 1 + 9 + 8 + 1 = 33; Reduce to a single digit: 3 + 3 = 6; Life Path Number is 6.",
  step3:
    "Calculate Your Numerological House Number Using the Chaldean Chart: C = 3, 4 = 4, 1 = 1, 0 = 0; Sum of digits: 3 + 4 + 1 + 0 = 8; Numerological House Number is 8.",
  step4:
    "Find Out If Your Life Path Number and Numerological House Number Are Good: Search the provided table or resources to see if Life Path Number 6 and Numerological House Number 8 are considered favorable.",
  step5:
    "Use the Tool for Family Members: Use the provided tool to input all family members’ dates of birth to get a comprehensive result.",
  step6:
    "Interpret Results: Interpret the results based on the information or tool to determine if the house number and Life Path Number are beneficial for the individual or family.",
};

export default exampleTextsHouseNumerology;
