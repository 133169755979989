const exampleTextsBusinessNumber = {
  step1: "Write Down Your Business Name: Example: Elite Numerology",
  step2:
    "Calculate the Business Name Number According to the Chaldean Numerology Chart. Example calculation for 'Elite Numerology': E(5) + L(3) + I(1) + T(4) + E(5) + N(5) + U(6) + M(4) + E(5) + R(2) + O(7) + L(3) + O(7) + G(3) + Y(1) = 61",
  step3:
    "Reduce the Total Sum to a Single Digit: Reduce the total sum (61) to a single digit: 6 + 1 = 7.",
  step4: "Determine Your Business Number: The resulting single digit is 7.",
  step5:
    "Understand the Traits of the Business Number: Number 7 Strengths: analytical prowess and intellectual depth; Number 7 Weaknesses: Can be too introspective or isolated.",
  step6:
    "Consider the Implications: Understanding the traits of your Business Number can help you make strategic decisions for your business.",
};

export default exampleTextsBusinessNumber;
