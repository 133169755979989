import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import kuaCompatibility from "../assets/KuaCompatibility-1.png";
import { kuaCompatibilityInstructions } from "./KuaCompatibility_How_To";
import "./Partner.css";
import ShareButtons from "../components/ShareButtons";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import {
  KUACOMPATIBILITY_FAQ_QUESTION_1,
  KUACOMPATIBILITY_FAQ_ANSWER_1,
  KUACOMPATIBILITY_FAQ_QUESTION_2,
  KUACOMPATIBILITY_FAQ_ANSWER_2,
  KUACOMPATIBILITY_FAQ_QUESTION_3,
  KUACOMPATIBILITY_FAQ_ANSWER_3,
  KUACOMPATIBILITY_FAQ_QUESTION_4,
  KUACOMPATIBILITY_FAQ_ANSWER_4,
  KUACOMPATIBILITY_FAQ_QUESTION_5,
  KUACOMPATIBILITY_FAQ_ANSWER_5,
  KUACOMPATIBILITY_FAQ_QUESTION_6,
  KUACOMPATIBILITY_FAQ_ANSWER_6,
  KUACOMPATIBILITY_FAQ_QUESTION_7,
  KUACOMPATIBILITY_FAQ_ANSWER_7,
  KUACOMPATIBILITY_FAQ_QUESTION_8,
  KUACOMPATIBILITY_FAQ_ANSWER_8,
  KUACOMPATIBILITY_FAQ_QUESTION_9,
  KUACOMPATIBILITY_FAQ_ANSWER_9,
  KUACOMPATIBILITY_FAQ_QUESTION_10,
  KUACOMPATIBILITY_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsKuaCompatibility from "./KuaCompatibility_Example_Text";
import ExampleUI from "../components/ExampleUI";

import Loader from "../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_1,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_1,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_2,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_2,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_3,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_3,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_4,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_4,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_5,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_5,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_6,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_6,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_7,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_7,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_8,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_8,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_9,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_9,
    },
    {
      question: KUACOMPATIBILITY_FAQ_QUESTION_10,
      answer: KUACOMPATIBILITY_FAQ_ANSWER_10,
    },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function KuaCompatibility({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const [loading] = useState(false);
  const url = window.location.href;
  const shareText =
    "It is so easy to find out how compatible my Kua Numbrt is with my partner using this cool tool. Wondering about your relationship? Check now at";
  const mouseTitle = "How compatible is your Kua Number with your Partner's?";
  const browserTitle = "Kua Number Compatibility";

  const [userDOB, setUserDOB] = useState(
    userData?.userDOB ? new Date(userData.userDOB) : null
  );
  const [partnerDOB, setPartnerDOB] = useState(null);
  const [userGender, setUserGender] = useState("M");
  const [partnerGender] = useState("F");
  const [compatibilityData, setCompatibilityData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    userDOB: "",
    partnerDOB: "",
  });
  const hasContent = compatibilityData !== null;

  const clearCompatibilityData = () => {
    setCompatibilityData(null);
  };

  const handleUserDOBChange = (date) => {
    setUserDOB(date);
    setErrors({ ...errors, userDOB: "" });
    setCompatibilityData(null); // Clear previous API result
  };

  const handlePartnerDOBChange = (date) => {
    setPartnerDOB(date);
    setErrors({ ...errors, partnerDOB: "" });
    setCompatibilityData(null); // Clear previous API result
  };

  useEffect(() => {
    if (userData) {
      setUserDOB(userData.userDOB ? new Date(userData.userDOB) : null);
    }
  }, [userData]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    setErrors({});

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }

    const newErrors = {};

    if (!userDOB) {
      newErrors.userDOB = "Please enter Date of Birth";
    }
    if (!partnerDOB) {
      newErrors.partnerDOB = "Please enter Date of Birth";
    }

    if (!userDOB || !partnerDOB) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    const startTime = Date.now(); // Record the start time

    // Decrement clicks remaining
    setClicksRemaining((prev) => prev - 1);

    // Start timer only on the first submit
    if (clicksRemaining === 5) {
      startTimer();
    }

    try {
      // Format dates as needed by the server
      const formattedUserDOB = formatDateForServer(userDOB);
      const formattedPartnerDOB = formatDateForServer(partnerDOB);

      const response = await fetch(
        "https://api.scanandplaytambola.com/kuaNumberCompatibility",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dob1: formattedUserDOB,
            gender1: userGender,
            dob2: formattedPartnerDOB,
            gender2: partnerGender,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Network response was not ok: ${response.status} - ${errorData.message}`
        );
      }

      const responseData = await response.json();

      // Ensure loader is displayed for a minimum of 5 seconds
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime;

      setTimeout(
        () => {
          setCompatibilityData(responseData);
          setIsLoading(false);
        },
        remainingTime > 0 ? remainingTime : 0
      );
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(`There was an error with your request: ${error.message}`);
      setIsLoading(false); // Ensure loader is turned off in case of error
    }
  };

  // Helper function to format date as 'dd-MM-yyyy' for server
  const formatDateForServer = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  return (
    <div className="Partner">
      <Helmet>
        <title>Kua Number Compatibility</title>
        <meta
          name="description"
          content="Analyze Kua Number Compatibility to determine the harmony between you and your partner, enhancing your relationship."
        />
      </Helmet>
      <div className="content">
        <br />
        <div className="input-and-result-window">
          <div className="special1">
            <h1>Kua Number Compatibility Calculator</h1>
            <div className="input-container">
              <div id="box" className="left-group">
                <h2>Partner 1</h2>
                <div className="input-group">
                  <p className="label">Enter your Date of Birth:</p>
                  <DatePicker
                    selected={userDOB}
                    onChange={handleUserDOBChange}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors.userDOB && (
                    <p className="error-message">{errors.userDOB}</p>
                  )}
                </div>
                <div className="input-group">
                  <p className="label">Select your Gender:</p>
                  <select
                    value={userGender}
                    onChange={(e) => {
                      setUserGender(e.target.value);
                      clearCompatibilityData();
                    }}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>
              <div id="box" className="right-group">
                <h2>Partner 2</h2>
                <div className="input-group">
                  <p className="label">Enter your Date of Birth:</p>
                  <DatePicker
                    selected={partnerDOB}
                    onChange={handlePartnerDOBChange}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors.partnerDOB && (
                    <p className="error-message">{errors.partnerDOB}</p>
                  )}
                </div>
                <div className="input-group">
                  <p className="label">Select your Gender:</p>
                  <select
                    value={partnerGender}
                    onChange={(e) => {
                      setUserGender(e.target.value);
                      clearCompatibilityData();
                    }}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {isLoading && <Loader />}
            <p>
              <strong>Compatibility:</strong>{" "}
              {isLoading || !compatibilityData
                ? ""
                : compatibilityData.compatibility}
            </p>
            <h4>Interpretation:</h4>{" "}
            <p>
              {isLoading || !compatibilityData
                ? ""
                : compatibilityData.interpretation}
            </p>
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Calculate Kua Compatibility</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: kuaCompatibilityInstructions.howToCalculate,
            }}
          />
          <img
            src={kuaCompatibility}
            alt="Kua Compatibility Chart"
            className="center-image-compatibility"
            width="800"
          />
        </div>
        <div>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
        <ExampleUI texts={exampleTextsKuaCompatibility} />
        <ShareButtons url={url} title={shareText} />
        <MouseFollower mouseTitle={mouseTitle} />{" "}
      </div>
    </div>
  );
}

export default KuaCompatibility;
