import React, { useState, useEffect, useContext } from "react";
import "./LifePath.css";
import { lifePathNumberInstructions } from "./LifePath_How_to";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import { API_BASE_URL } from "../components/Constants";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import { GlobalStateContext } from "../components/GlobalVariable";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import ExampleUI from "../components/ExampleUI"; // Adjust the import path if necessary
import exampleTextsLifePathNumber from "./LifePath_Example_Text"; // Adjust the import path if necessary
import {
  LIFE_PATH_FAQ_QUESTION_1,
  LIFE_PATH_FAQ_ANSWER_1,
  LIFE_PATH_FAQ_QUESTION_2,
  LIFE_PATH_FAQ_ANSWER_2,
  LIFE_PATH_FAQ_QUESTION_3,
  LIFE_PATH_FAQ_ANSWER_3,
  LIFE_PATH_FAQ_QUESTION_4,
  LIFE_PATH_FAQ_ANSWER_4,
  LIFE_PATH_FAQ_QUESTION_5,
  LIFE_PATH_FAQ_ANSWER_5,
  LIFE_PATH_FAQ_QUESTION_6,
  LIFE_PATH_FAQ_ANSWER_6,
  LIFE_PATH_FAQ_QUESTION_7,
  LIFE_PATH_FAQ_ANSWER_7,
  LIFE_PATH_FAQ_QUESTION_8,
  LIFE_PATH_FAQ_ANSWER_8,
  LIFE_PATH_FAQ_QUESTION_9,
  LIFE_PATH_FAQ_ANSWER_9,
  LIFE_PATH_FAQ_QUESTION_10,
  LIFE_PATH_FAQ_ANSWER_10,
} from "../components/Constants";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: LIFE_PATH_FAQ_QUESTION_1, answer: LIFE_PATH_FAQ_ANSWER_1 },
    { question: LIFE_PATH_FAQ_QUESTION_2, answer: LIFE_PATH_FAQ_ANSWER_2 },
    { question: LIFE_PATH_FAQ_QUESTION_3, answer: LIFE_PATH_FAQ_ANSWER_3 },
    { question: LIFE_PATH_FAQ_QUESTION_4, answer: LIFE_PATH_FAQ_ANSWER_4 },
    { question: LIFE_PATH_FAQ_QUESTION_5, answer: LIFE_PATH_FAQ_ANSWER_5 },
    { question: LIFE_PATH_FAQ_QUESTION_6, answer: LIFE_PATH_FAQ_ANSWER_6 },
    { question: LIFE_PATH_FAQ_QUESTION_7, answer: LIFE_PATH_FAQ_ANSWER_7 },
    { question: LIFE_PATH_FAQ_QUESTION_8, answer: LIFE_PATH_FAQ_ANSWER_8 },
    { question: LIFE_PATH_FAQ_QUESTION_9, answer: LIFE_PATH_FAQ_ANSWER_9 },
    { question: LIFE_PATH_FAQ_QUESTION_10, answer: LIFE_PATH_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function LifePath({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const [,] = useState("");
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };

  const url = window.location.href;
  const mouseTitle = "Find your Life Path Number";
  const browserTitle = "Life Path Number";
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const endpoint = "LifePathNumber"; // Example endpoint, adjust as needed
  const [day, setDay] = useState(userData?.day || 1);
  const [month, setMonth] = useState(userData?.month || 1);
  const [year, setYear] = useState(userData?.year || 1900);
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [lifePathResult, setLifePathResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const shareText =
    "Hi , My Life Path Numbers says so much about me . Find out your life path number with this amazing tool at";

  const hasContent = lifePathResult !== null || error;

  useEffect(() => {
    if (userData) {
      setDay(userData.day || 1);
      setMonth(userData.month || 1);
      setYear(userData.year || 1900);
    }
  }, [userData]);

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setLifePathResult(null); // Clear previous API result
  };

  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setLifePathResult(null); // Clear previous API result
  };

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setLifePathResult(null); // Clear previous API result
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);

    setLifePathResult(null); // Clear previous API result

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }

    setLoading(true);
    const startTime = Date.now();

    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const apiUrl = `${API_BASE_URL}${endpoint}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ dateOfBirth: formattedDate }),
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime;

      // Ensure that the loader is displayed for at least 5 seconds
      setTimeout(
        () => {
          setLifePathResult(data);
          setError(null);
          setLoading(false);

          // Update user data with edited values
          setUserData({
            ...userData,
            day: day,
            month: month,
            year: year,
          });
        },
        remainingTime > 0 ? remainingTime : 0
      );
    } catch (error) {
      setError("Failed to fetch data from the API");
      console.error("Error:", error);
      setLoading(false);
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  return (
    <div className="LifePath">
      <Helmet>
        <title>Life Path Number</title>
        <meta
          name="description"
          content="Calculate your Life Path Number to uncover your life's purpose, strengths, and challenges based on your birth date."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <br />
            <h1>Life Path Number Calculator</h1>
            <div>
              <DateOfBirthSelector
                day={day}
                month={month}
                year={year}
                daysInMonth={daysInMonth}
                handleDayChange={handleDayChange}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              <b>Life Path Number:</b>{" "}
              {lifePathResult ? lifePathResult.lifePathNumber : ""}
            </p>
            <h4>Interpretation:</h4>{" "}
            <p>{lifePathResult ? lifePathResult.interpretation : ""}</p>
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate Life Path Number:</h2>
          {lifePathNumberInstructions.howToCalculate}
        </div>
        <ExampleUI texts={exampleTextsLifePathNumber} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default LifePath;
