const exampleTextsMaturity = {
  step1:
    "Write Down Your Full Date of Birth and Full Name: Name: Ashish Batra; Date of Birth: 10/10/1989.",
  step2:
    "Calculate Your Name Number: Assign numbers to each letter using the Chaldean Numerological Chart and sum them. For Ashish Batra, the Name Number is 1.",
  step3:
    "Calculate Your Life Path Number: Add all digits of your birth date and reduce to a single digit. For 10/10/1989, the Life Path Number is 2.",
  step4:
    "Reduce Both Numbers to a Single Digit: Name Number is 1 and Life Path Number is 2, both are single digits.",
  step5:
    "Sum Both Numbers Together and Reduce to a Single Number: Sum of Name Number (1) and Life Path Number (2) is 3.",
  step6:
    "Interpret the Result: The final number is 3, which can be used for further numerological interpretation.",
};

export default exampleTextsMaturity;
