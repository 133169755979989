export const mobileNumberInstructions = {
  howToCalculate: `
    <p>Write down your mobile number.</p>

    <p>Sum all digits together and reduce it to a single number.</p>

    <p>Interpretation is deduced based on the numerological value of your mobile number.</p>

    <p>Our algorithm also checks multiple combinations like triple numbers, double numbers, 28, and many other combinations of numbers to deduce interpretation and find out the primary influence number.</p>
  `,
};
