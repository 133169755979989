export const pinnacleInstructions = {
  howToCalculate: `
    <p>Write down your full date of birth in the format DD-MM-YYYY.</p>

    <p>Sum the digits of your birth date (day, month, and year) separately and reduce each to a single digit.</p>

    <p>First Pinnacle Number: Add the month and day of birth and reduce the sum to a single digit.</p>

    <p>Fecond Pinnacle Number: Add the day and year of birth and reduce the sum to a single digit.</p>

    <p>Third Pinnacle Number: Add the first and second pinnacle numbers. Reduce the sum to a single digit.</p>

    <p>Gourth Pinnacle Number: Add the month and year of birth. Reduce the sum to a single digit.</p>

    <p><b>Pinnacle Cycle:</b></p>
    <p>First: Duration: From birth to age 36 minus the Life Path Number. Click <a class="midpagelink" href="/life-path-number-calculator">here</a> to understand how to calculate Life Path Number</a>.</p>
    <p>Decond: 9 years following the end of the first pinnacle cycle.</p>
    <p>Third: 9 years following the end of the second pinnacle cycle.</p>
    <p>Fourth: From the end of the third pinnacle cycle to the end of life.</p>
  `,
};
