const exampleTextsLifePathNumber = {
  step1: "Example: 10-10-1989",
  step2: "Write down your date of birth in DD-MM-YYYY format: Date of birth: 10-10-1989",
  step3: "Add all digits together: 1 + 0 + 1 + 0 + 1 + 9 + 8 + 9 = 29",
  step4: "If the sum is greater than 9, sum both digits again until the sum is a single digit: 2 + 9 = 11",
  step5: "Check if the result is a Master Number: The result is 11, which is a Master Number.",
  step6: "Life Path Number: The Life Path Number is 11.",
};

export default exampleTextsLifePathNumber;
