export const soulUrgeInstructions = {
  howToCalculate: `
    <p><b>Step 1:</b> Write down your full name.</p>
    <p><b>Step 2:</b> Identify and isolate the vowels in your full name.</p>
    <p><b>Step 3:</b> Use the Chaldean numeric chart to find the corresponding digits for each vowel in your full name.</p>
    <p><b>Step 4:</b> Add all the assigned digits for the vowels together to get the total sum value.</p>
    <p><b>Step 5:</b> Reduce the total sum to a single digit by adding the digits together, unless the sum is a master number (11, 22, or 33). If it is a master number, do not reduce further.</p>
    <p><b>Result:</b> The resulting single digit or master number is your Soul Urge Number.</p>
  `,
};
