const exampleTextsSoulUrge = {
  step1: "Write Down Your Full Name: Example: Ashish Batra",
  step2:
    "Identify and Isolate the Vowels in Your Full Name: Ashish Batra; Vowels: A, I, A, A",
  step3:
    "Use the Chaldean Numeric Chart to Find the Corresponding Digits for Each Vowel: A = 1, I = 1",
  step4:
    "Add All the Assigned Digits for the Vowels Together: A(1) + I(1) + A(1) + A(1) = 1 + 1 + 1 + 1 = 4",
  step5:
    "Reduce the Total Sum to a Single Digit: The total sum is 4, which is already a single digit and not a master number.",
  step6:
    "Determine the Soul Urge Number: The Soul Urge Number for Ashish Batra is 4.",
};

export default exampleTextsSoulUrge;
