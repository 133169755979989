export const subconsciousSelfInstructions = {
  howToCalculate: `
    <p>Write down your full name exactly as it appears.</p>

    <p>Use the Pythagorean numerology chart to find the corresponding digits for each letter in your full name.</p>

    <p>Assign the corresponding digit to each letter according to the numeral chart.</p>

    <p>Identify the unique digits present in your name; each digit will count as one, regardless of how many times it appeared.</p>

    <p>Count the total number of unique digits present in your full name.</p>

    <p>The total number of unique digits is your Subconscious Self Number.</p>
  `,
};
