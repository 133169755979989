export const destinyInstructions = {
  howToCalculate: `
    <p><b>Step 1:</b> Write down your full name.</p>
    <p><b>Step 2:</b> Use the Chaldean Numerological Chart below to find the corresponding digits for each letter in your name.</p>
    <p><b>Step 3:</b> Assign the corresponding digit to each letter in your name according to the Chaldean chart.</p>
    <p><b>Step 4:</b> Add all the assigned digits together to get the total sum value.</p>
    <p><b>Step 5:</b> Reduce the total sum to a single digit by adding the digits together, unless the sum is a master number (11, 22, or 33).</p>
    <p><b>Result:</b> The resulting single digit or master number is your Chaldean Destiny Number.</p>
  `,
};
