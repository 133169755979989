import chaldeanChartImage from "../assets/Chaldean-1.png";
import compatibilityChart from "../assets/Compatibility-1.png";

// Export the instructions with images included
export const houseNumberInstructions = {
  howToCalculate: (
    <div>
      <p>
        <b>
          Write down your date of birth and complete house number (we recommend
          including tower number or block number, like K213, C410, etc).
        </b>
      </p>
      <p>
        Calculate your Life Path Number (click{" "}
        <a class="midpagelink" href="/life-path-number-calculator">
          here
        </a>{" "}
        to find how to calculate Life Path Number).{" "}
      </p>
      <p>Calculate your Numerological House Number using the Chaldean Chart.</p>
      <img
        className="center-image-chaldean"
        src={chaldeanChartImage}
        alt="Chandelean Chart"
      />
      <p>
        Once you have your Numerological House Number and Life Path Number, you
        can search in the table below to figure out if it is good or not.
      </p>
      <img
        className="center-image-compatibility"
        src={compatibilityChart}
        alt="Chandelean Chart"
      />
      <p>
        Also, we feel a house is not just for an individual; that is why we
        provide you with a tool that can take all family members' DOBs and
        quickly find the result.
      </p>
    </div>
  ),
};
