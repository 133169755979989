import React from "react";
import "./ShareButtons.css";
import {
  FaWhatsapp,
  FaFacebook,
  FaLinkedin,
  FaPinterest,
  FaInstagram,
  FaTwitterSquare,
  FaLink,
  FaBookmark,
} from "react-icons/fa";
import TwitterXLogo from "../assets/TwitterXLogo.svg"; // Import your SVG icon here

function ShareButtons({ url, shareText }) {
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => alert("Link copied to clipboard!"))
      .catch(() =>
        alert("Failed to copy link. Press Ctrl+C to copy manually.")
      );
  };

  const handleBookmark = () => {
    const supportedBrowsers = [
      "safari",
      "chrome",
      "chromium",
      "ie",
      "edge",
      "firefox",
    ];
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if browser is supported for automatic bookmarking
    if (supportedBrowsers.some((browser) => userAgent.includes(browser))) {
      if (window.clipboard) {
        // Modern browsers with clipboard API
        handleCopyLink(); // Copy link to clipboard for generic bookmarking
      } else {
        // Legacy browsers without clipboard API
        alert(
          "Automatic bookmarking not supported. Press Ctrl+D to bookmark this page."
        );
      }
    } else {
      alert("Press Ctrl+D to bookmark this page."); // Manual instructions for unsupported browsers
    }
  };

  return (
    <div className="share-buttons">
      <a
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
          shareText + " " + url
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          url
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin />
      </a>
      <a
        href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
          url
        )}&description=${encodeURIComponent(shareText)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaPinterest />
      </a>
      <a
        href={`https://www.instagram.com/?url=${encodeURIComponent(url)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(shareText)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="twitter-logo" src={TwitterXLogo} alt="Twitter Logo" />{" "}
        {/* Use the imported SVG icon */}
      </a>
      <button onClick={handleCopyLink}>
        <FaLink /> Copy Link
      </button>
      <button onClick={handleBookmark}>
        <FaBookmark /> Save to Favourites
      </button>
    </div>
  );
}

export default ShareButtons;
