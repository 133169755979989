export const karmicInstructions = {
  howToCalculate: `
    <p>Write down your date of birth in the format DD-MM-YYYY and your full name.</p>

    <p>Use the Pythagorean numerology chart to find the corresponding digits for each letter in your full name.</p>

    <p>Add all the assigned digits for the letters in your full name together.</p>

    <p>Reduce the total sum to a single digit by adding the digits together, unless the sum is a master number (11, 22, or 33).</p>

    <p>Check if the resulting single digit is one of the Karmic Debt Numbers: 13, 14, 16, or 19.</p>

    <p>Identify any Karmic Debt Numbers (13, 14, 16, or 19) within your birth date by summing the digits of each component (day, month, year) and checking for these specific numbers.</p>

    <p><b>Result:</b><br>
    If your resulting single digit from the full name or any part of your date of birth matches 13, 14, 16, or 19, you have Karmic Debt.</p>
  `,
};
