import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./Passion.css";
import pythagoreanChart from "../assets/Pythagorean-1.png";
import { passionInstructions } from "./Passion_How_To";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import {
  PASSION_FAQ_QUESTION_1,
  PASSION_FAQ_ANSWER_1,
  PASSION_FAQ_QUESTION_2,
  PASSION_FAQ_ANSWER_2,
  PASSION_FAQ_QUESTION_3,
  PASSION_FAQ_ANSWER_3,
  PASSION_FAQ_QUESTION_4,
  PASSION_FAQ_ANSWER_4,
  PASSION_FAQ_QUESTION_5,
  PASSION_FAQ_ANSWER_5,
  PASSION_FAQ_QUESTION_6,
  PASSION_FAQ_ANSWER_6,
  PASSION_FAQ_QUESTION_7,
  PASSION_FAQ_ANSWER_7,
  PASSION_FAQ_QUESTION_8,
  PASSION_FAQ_ANSWER_8,
  PASSION_FAQ_QUESTION_9,
  PASSION_FAQ_ANSWER_9,
  PASSION_FAQ_QUESTION_10,
  PASSION_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsHiddenPassion from "./Passion_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: PASSION_FAQ_QUESTION_1, answer: PASSION_FAQ_ANSWER_1 },
    { question: PASSION_FAQ_QUESTION_2, answer: PASSION_FAQ_ANSWER_2 },
    { question: PASSION_FAQ_QUESTION_3, answer: PASSION_FAQ_ANSWER_3 },
    { question: PASSION_FAQ_QUESTION_4, answer: PASSION_FAQ_ANSWER_4 },
    { question: PASSION_FAQ_QUESTION_5, answer: PASSION_FAQ_ANSWER_5 },
    { question: PASSION_FAQ_QUESTION_6, answer: PASSION_FAQ_ANSWER_6 },
    { question: PASSION_FAQ_QUESTION_7, answer: PASSION_FAQ_ANSWER_7 },
    { question: PASSION_FAQ_QUESTION_8, answer: PASSION_FAQ_ANSWER_8 },
    { question: PASSION_FAQ_QUESTION_9, answer: PASSION_FAQ_ANSWER_9 },
    { question: PASSION_FAQ_QUESTION_10, answer: PASSION_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Passion({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const url = window.location.href;
  const mouseTitle = "Find your Hidden Passion Number";
  const browserTitle = "Hidden Passion Number";
  const shareText =
    "Hi, I uncovered my deepest talents with this tool. It result is amazing! Curious about your hidden skills? Discover them at";
  const [fullName, setFullName] = useState(userData?.fullName || "");
  const [passionNumber, setPassionNumber] = useState(null);
  const [interpretation, setInterpretation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hasContent = passionNumber !== null;

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
    setInterpretation(null); // Clear previous API result
    setPassionNumber(null); // Clear previous result
  };

  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName || "");
    }
  }, [userData]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    if (!fullName) {
      setError("Please enter your Full Name");
      return;
    }

    setUserData({
      ...userData,
      fullName: fullName,
    });

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/passionNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: fullName,
            system: "C", // Assuming 'C' is the correct system to use
          }),
        }
      );

      const data = await response.json();

      // Ensure the loader is visible for at least 5 seconds
      setTimeout(() => {
        setPassionNumber(data.passionNumber);
        setInterpretation(data.interpretation);
        setLoading(false);
      }, 5000);
    } catch (error) {
      console.error("Error fetching the passion number:", error);
      setLoading(false);
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="Passion">
      <Helmet>
        <title>Hidden Passion Number</title>
        <meta
          name="description"
          content="Reveal your Hidden Passion Number to uncover your deepest talents and passions that influence your life’s journey."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1> What is your Hidden Passion?</h1>
            <div>
              <p className="label">Enter your Full Name:</p>
              <input
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Type here..."
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              <b>Passion Number :</b> {loading ? "" : passionNumber}
            </p>
            <h4>Interpretation :</h4> {loading ? "" : interpretation}
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Feel free to share with Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Hidden Passion Number Calculation</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: passionInstructions.howToCalculate,
            }}
          />
          <img
            src={pythagoreanChart}
            alt="Pythagorean Numerology Chart"
            className="center-image-pythagorean"
            width="800"
          />
        </div>
        <ExampleUI texts={exampleTextsHiddenPassion} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default Passion;
