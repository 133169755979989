// Import your images
import loShuGridImage from "../assets/LoShuGrid-1.png";
import chaldeanChartImage from "../assets/Chaldean-1.png";
import loShuGridPlanesImage from "../assets/LoShuGrid-2.png";

// Export the instructions with images included
export const loShuGridInstructions = {
  howToCalculate: (
    <div>
      <p>
        Write down your date of birth in DD-MM-YYYY format and name (optional).
        <br />
        <br />
        <b>Calculate Driver Number:</b> Sum of DD from your date of birth.
        <br />
        <br />
        <b>Calculate the Conductor Number:</b> Sum of all the digits of your
        date of birth together until you get a single digit.
        <br />
        <br />
        <img
          className="center-image-loshu1"
          src={loShuGridImage}
          alt="Lo Shu Grid"
        />
        <br />
        <br />
        <b>(Optional) Calculate your Name Number:</b> Sum of all the alphabets
        of your name reduced to a single digit using the chart below.
        <br />
        <br />
        <img
          className="center-image-chaldean"
          src={chaldeanChartImage}
          alt="Chandelean Chart"
        />
        <br />
        <br />
        Now place individual digits of DOB in Lo Shu grid table, including
        driver number, conductor number, and name number as well.
        <br />
        Place the compiled numbers in the Lo Shu Grid at their fixed positions.
        Note that numbers missing from the range 1 to 9 will not appear in the
        grid.
        <br />
        <br />
        Using these steps, you can calculate your Lo Shu Grid by hand for any
        given date of birth and name. Some people don't include name number in
        Lo Shu Grid, so we have made the name field optional in the table above.
        <br />
        <br />
        <img
          className="center-image-loshu2"
          src={loShuGridPlanesImage}
          alt="Lo Shu Grid Planes"
        />
      </p>
    </div>
  ),
};
