import React from "react";

const ExampleUI = ({ texts }) => {
  return (
    <>
      <div className="example-container">
        <div className="box1">
          <p className="example-number">1</p>
          <p className="example-step1">{texts.step1}</p>
          <hr className="example-line-dotted"></hr>
          <hr className="example-line" />
        </div>
        <div className="box2">
          <p className="example-number">2</p>
          <p className="example-step2">{texts.step2}</p>
          <hr className="example-line-dotted"></hr>
          <hr className="example-line" />
        </div>
        <div className="box2">
          <p className="example-number">3</p>
          <p className="example-step3">{texts.step3}</p>
          <hr className="example-line-dotted"></hr>
          <hr className="example-line" />
        </div>
        <div className="box1">
          <p className="example-number">4</p>
          <p className="example-step4">{texts.step4}</p>
          <hr className="example-line-dotted"></hr>
          <hr className="example-line" />
        </div>
        <div className="box2">
          <p className="example-number">5</p>
          <p className="example-step5">{texts.step5}</p>
          <hr className="example-line-dotted"></hr>
          <hr className="example-line" />
        </div>
      </div>
      <div className="example-container-last">
        <div className="boxLast">
          <p className="example-number">6</p>
          <p className="example-step6">{texts.step6}</p>
          <hr className="example-line-last" />
          <hr className="example-line-dotted-last"></hr>
        </div>
      </div>
    </>
  );
};

export default ExampleUI;
