export const lifePathNumberInstructions = {
  howToCalculate: (
    <div>
      <p>
        Write down your date of birth in DD-MM-YYYY format.
        <br />
        Add all digits together, i.e., D + D + M + M + Y + Y + Y + Y.
        <br />
        If the sum is greater than 9, then sum both digits again until the sum
        is a single digit.
        <br />
        At any time during the sum, if the result is 11, 22, or 33, stop as
        these are considered Master Numbers.
      </p>
    </div>
  ),

  example: (
    <div>
      <p>
        Example: 10-10-1989
        <br />
        Write down your date of birth in DD-MM-YYYY format:
        <br />
        <br />
        Date of birth: 10-10-1989
        <br />
        Add all digits together:
        <br />
        <br />
        1 + 0 + 1 + 0 + 1 + 9 + 8 + 9 = 29
        <br />
        If the sum is greater than 9, sum both digits again until the sum is a
        single digit:
        <br />
        <br />
        2 + 9 = 11
        <br />
        Check if the result is a Master Number:
        <br />
        <br />
        The result is 11, which is a Master Number.
        <br />
        Life Path Number:
        <br />
        <br />
        The Life Path Number is 11.
      </p>
    </div>
  ),
};
