// KuaNumber_How_To.js

export const kuaNumberInstructions = {
  howToCalculate: (
    <div>
      <p>
        For Males:
        <br />
        Sum and reduce the last two digits of your birth year to a single digit.
        <br />
        If born before 2000, subtract this single digit from 10.
        <br />
        If born in or after 2000, add 9 to the single digit, then subtract from
        10.
        <br />
        The result is your Kua number.
      </p>

      <p>
        For Females:
        <br />
        Sum and reduce the last two digits of your birth year to a single digit.
        <br />
        Add 5 to this single digit and reduce again to a single digit.
        <br />
        If born in or after 2000, subtract 4 from the single digit.
        <br />
        The result is your Kua number.
      </p>

      <p>
        Important Note:
        <br />
        For Kua Number 5, If your calculation results in 5, use Kua number 2 for
        males and Kua number 8 for females.
      </p>
    </div>
  ),

  example1: (
    <div>
      <p>
        Example 1: Male, 10-10-1989
        <br />
        Sum and reduce the last two digits of the birth year to a single digit:
        <br />
        <br />
        Last two digits of 1989: 8 + 9 = 17
        <br />
        Reduce 17 to a single digit: 1 + 7 = 8<br />
        If born before 2000, subtract this single digit from 10:
        <br />
        <br />
        10 - 8 = 2<br />
        <br />
        Kua number:
        <br />
        <br />
        The result is 2.
      </p>
    </div>
  ),

  example2: (
    <div>
      <p>
        Example 2: Female, 10-10-1989
        <br />
        Sum and reduce the last two digits of the birth year to a single digit:
        <br />
        <br />
        Last two digits of 1989: 8 + 9 = 17
        <br />
        Reduce 17 to a single digit: 1 + 7 = 8<br />
        Add 5 to this single digit and reduce again to a single digit:
        <br />
        <br />
        8 + 5 = 13
        <br />
        Reduce 13 to a single digit: 1 + 3 = 4<br />
        <br />
        Kua number:
        <br />
        <br />
        The result is 4.
      </p>
    </div>
  ),
};
