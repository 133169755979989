const exampleTextsSubconsciousSelf = {
  step1: "Write Down Your Full Name: Ashish Batra.",
  step2: "Use the Pythagorean Numerology Chart to map each letter to a digit.",
  step3:
    "Assign Corresponding Digits to Each Letter: A = 1, S = 1, H = 8, I = 9, S = 1, H = 8, B = 2, A = 1, T = 2, R = 9, A = 1.",
  step4: "Identify the Unique Digits Present in Your Name: 1, 2, 8, 9.",
  step5: "Count the Total Number of Unique Digits Present in Your Name: 4.",
  step6:
    "Determine Your Subconscious Self Number: The Subconscious Self Number is 4.",
};

export default exampleTextsSubconsciousSelf;
