const exampleTextsNameNumber = {
  step1: "Write Down Your Full Name: Ashish Batra.",
  step2:
    "Refer to the Chaldean Numerology Chart to find the number of each consonant in your name.",
  step3:
    "Assign Digits to Each Consonant: S = 3, H = 5, S = 3, B = 2, T = 4, R = 2.",
  step4: "Add All Assigned Digits Together: 3 + 5 + 3 + 2 + 4 + 2 = 19.",
  step5: "Reduce the Total Sum to a Single Digit: 19 → 1 + 9 = 10 → 1 + 0 = 1.",
  step6: "The Resulting Number is Your Name Number: 1.",
};

export default exampleTextsNameNumber;
