const exampleTextsCompatibility = {
  step1:
    "Write Down the Names and Dates of Birth of Both Partners: Utkarsh Mishra, Date of Birth: 01/01/1989; Mahi Agarwal, Date of Birth: 02/03/1991.",
  step2:
    "Calculate the Name Number for Both Partners: For Utkarsh Mishra, Name Number is 3; For Mahi Agarwal, Name Number is 1.",
  step3:
    "Calculate the Life Path Number for Both Partners: For Utkarsh Mishra, Life Path Number is 3; For Mahi Agarwal, Life Path Number is 1.",
  step4:
    "Calculate Name Compatibility Using the Chart: Refer to the Name Compatibility Chart to analyze the interaction between Name Numbers 3 and 1.",
  step5:
    "Calculate Relationship Based on Life Path Numbers Using the Grid: Refer to the Life Path Number Relationship Grid to analyze compatibility between Life Path Numbers 3 and 1.",
  step6:
    "Determine Overall Compatibility: Combine insights from Name Compatibility and Life Path Compatibility to assess the overall compatibility between the partners.",
};

export default exampleTextsCompatibility;
