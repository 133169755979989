import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.css";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Check if current location is the homepage ("/")
  const isHomepage = location.pathname === "/";

  // If it's the homepage, do not render breadcrumbs
  if (isHomepage) return null;

  return (
    <nav aria-label="Breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link className="bread-home" to="/">
            Home
          </Link>
          {pathnames.length > 0 && (
            <span className="breadcrumb-separator"> » </span>
          )}
        </li>
        {pathnames.map((name, index) => {
          const capitalized = capitalizeFirstLetter(name);
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return (
            <React.Fragment key={name}>
              <li className={`breadcrumb-item ${isLast ? "active" : ""}`}>
                {isLast ? (
                  <span>{capitalized}</span>
                ) : (
                  <Link to={routeTo}>{capitalized}</Link>
                )}
                {!isLast && <span className="breadcrumb-separator"> » </span>}
              </li>
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
