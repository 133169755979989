import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./Maturity.css";
import { maturityInstructions } from "./Maturity_How_To";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import MouseFollower from "../components/MouseFollower";
import {
  MATURITY_FAQ_QUESTION_1,
  MATURITY_FAQ_ANSWER_1,
  MATURITY_FAQ_QUESTION_2,
  MATURITY_FAQ_ANSWER_2,
  MATURITY_FAQ_QUESTION_3,
  MATURITY_FAQ_ANSWER_3,
  MATURITY_FAQ_QUESTION_4,
  MATURITY_FAQ_ANSWER_4,
  MATURITY_FAQ_QUESTION_5,
  MATURITY_FAQ_ANSWER_5,
  MATURITY_FAQ_QUESTION_6,
  MATURITY_FAQ_ANSWER_6,
  MATURITY_FAQ_QUESTION_7,
  MATURITY_FAQ_ANSWER_7,
  MATURITY_FAQ_QUESTION_8,
  MATURITY_FAQ_ANSWER_8,
  MATURITY_FAQ_QUESTION_9,
  MATURITY_FAQ_ANSWER_9,
  MATURITY_FAQ_QUESTION_10,
  MATURITY_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsMaturity from "./Maturity_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: MATURITY_FAQ_QUESTION_1, answer: MATURITY_FAQ_ANSWER_1 },
    { question: MATURITY_FAQ_QUESTION_2, answer: MATURITY_FAQ_ANSWER_2 },
    { question: MATURITY_FAQ_QUESTION_3, answer: MATURITY_FAQ_ANSWER_3 },
    { question: MATURITY_FAQ_QUESTION_4, answer: MATURITY_FAQ_ANSWER_4 },
    { question: MATURITY_FAQ_QUESTION_5, answer: MATURITY_FAQ_ANSWER_5 },
    { question: MATURITY_FAQ_QUESTION_6, answer: MATURITY_FAQ_ANSWER_6 },
    { question: MATURITY_FAQ_QUESTION_7, answer: MATURITY_FAQ_ANSWER_7 },
    { question: MATURITY_FAQ_QUESTION_8, answer: MATURITY_FAQ_ANSWER_8 },
    { question: MATURITY_FAQ_QUESTION_9, answer: MATURITY_FAQ_ANSWER_9 },
    { question: MATURITY_FAQ_QUESTION_10, answer: MATURITY_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Maturity({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const [loading] = useState(false);
  const url = window.location.href;
  const mouseTitle = "Find your Maturity Number";
  const browserTitle = "Maturity Number";
  const shareText =
    "Hi, curious about what the future holds for you? My Maturity Number revealed some interesting paths. Find out yours at";
  const [fullName, setFullName] = useState(userData?.fullName || "");
  const [day, setDay] = useState(userData?.day || 23);
  const [month, setMonth] = useState(userData?.month || 10);
  const [year, setYear] = useState(userData?.year || 1990);
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [maturityNumber, setMaturityNumber] = useState(null);
  const [maturityMeaning, setMaturityMeaning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [fullNameError, setFullNameError] = useState(""); // State for full name error message
  const hasContent = maturityNumber !== null;

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        setFullNameError("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
    if (!event.target.value.trim()) {
      setFullNameError("Please enter your Full Name");
    } else {
      setFullNameError("");
    }
    setShowResponse(null); // Clear previous API result
    setMaturityNumber(null); // Clear previous result
    setMaturityMeaning(""); // Clear previous result
  };

  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName || "");
    }
  }, [userData]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setShowResponse(null); // Clear previous API result
    setMaturityNumber(null); // Clear previous result
    setMaturityMeaning(""); // Clear previous result
  };

  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setShowResponse(null); // Clear previous API result
    setMaturityNumber(null); // Clear previous result
    setMaturityMeaning(""); // Clear previous result
  };

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setShowResponse(null); // Clear previous API result
    setMaturityNumber(null); // Clear previous result
    setMaturityMeaning(""); // Clear previous result
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    if (!fullName || !day || !month || !year) {
      setFullNameError("Please enter your Full Name");
      return;
    }

    setIsLoading(true);
    setShowResponse(false);
    const dateOfBirth = `${day}-${month}-${year}`;
    const requestData = {
      dateOfBirth,
      name: fullName,
      system: "C",
    };

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/maturityNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setMaturityNumber(data.maturity_Number);
      setMaturityMeaning(data.maturity_Number_Meaning);

      setTimeout(() => {
        setIsLoading(false);
        setShowResponse(true);
      }, 5000); // Ensure the loader runs for at least 5 seconds
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setIsLoading(false);
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setDay(userData.day || 23);
      setMonth(userData.month || 10);
      setYear(userData.year || 1990);
    }
  }, [userData]);

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="Maturity">
      <Helmet>
        <title>Maturity Number</title>
        <meta
          name="description"
          content="Calculate your Maturity Number to gain insights into your true self and the fulfillment you seek later in life."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1>Discover Your Maturity Number</h1>
            <div>
              <p className="label">Enter your Full Name:</p>
              <input
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Type here..."
              />
              {fullNameError && (
                <p className="error-message">{fullNameError}</p>
              )}
            </div>
            <div>
              <DateOfBirthSelector
                day={day}
                month={month}
                year={year}
                daysInMonth={daysInMonth}
                handleDayChange={handleDayChange}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {isLoading && <Loader />}
            <p>
              <b>Maturity Number :</b> {isLoading ? "" : maturityNumber}
            </p>
            <h4>Interpretation :</h4> {isLoading ? "" : maturityMeaning}
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Maturity Number Calculation</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: maturityInstructions.howToCalculate,
            }}
          />
        </div>
        <ExampleUI texts={exampleTextsMaturity} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default Maturity;
