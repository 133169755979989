const exampleTextsPinnacle = {
  step1: "Write Down Your Full Date of Birth: 10-10-1989.",
  step2: "Sum the Digits of Your Birth Date: Day = 1, Month = 1, Year = 9.",
  step3:
    "Calculate Pinnacle Numbers: First Pinnacle Number = 2, Second Pinnacle Number = 2, Third Pinnacle Number = 2, Fourth Pinnacle Number = 2",
  step4:
    "First Pinnacle Cycle lasts from birth to age 36 minus the Life Path Number (11) = 25 years. Second Pinnacle Cycle: From age 26 to 34, Third Pinnacle Cycle: From age 35 to 43, Fourth Pinnacle Cycle: From age 44 onwards.",
  step5:
    "Interpret Pinnacle Numbers and Cycles for further numerological insights.",
  step6:
    "Utilize Pinnacle Cycles for personal and professional planning according to the cycle periods.",
};

export default exampleTextsPinnacle;
