export const balanceInstructions = {
  howToCalculate: `
    <p>Write down your full name exactly as it appears (First Name, Middle Name, Last Name).</p>

    <p>Take the initials of the first name, middle name, and last name.</p>

    <p>Assign the corresponding digit to all initials - from the Pythagorean numerology chart below.</p>

    <p>Add all the assigned digits for the initials together to get the total sum value.</p>

    <p>Reduce the total sum to a single digit by adding the digits together.</p>

    <p>Using these steps, you can calculate your Balance Number by hand for any given full name.</p>
  `,
};
