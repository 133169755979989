import React, { useEffect } from "react";
import "./AboutUs.css";
import logo2 from "../assets/logo2.svg";
import logo1 from "../assets/logo1.png";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const browserTitle = "About Us";
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once
  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="At Elite Numerology, we are dedicated to harnessing the profound insights of numerology to enrich your life. Founded by Ashish Battra, a Reiki Grandmaster, Numerologist , IT professional, and avid enthusiast of the occult sciences, Elite Numerology brings together ancient wisdom and modern technology in one accessible platform."
          title="About Us"
        />
      </Helmet>
      <h1 className="about-us-h1">About Us</h1>
      <div className="about-us">
        <div className="column">
          <div className="section" id="welcome">
            <h2>Welcome to Numerologist</h2>
            <p>
              At Elite Numerology, we are dedicated to harnessing the profound
              insights of numerology to enrich your life. Founded by Ashish
              Battra, a Reiki Grandmaster, Numerologist , IT professional, and
              avid enthusiast of the occult sciences, Elite Numerology brings
              together ancient wisdom and modern technology in one accessible
              platform.
            </p>
          </div>
          <div className="section" id="image1">
            <img className="about-us-img" src={logo1} alt="Image 1" />
          </div>
          <div className="section" id="founder">
            <h2>Founder’s Background</h2>
            <p>
              Ashish Battra has long been fascinated by the power of numbers and
              their influence on our lives. His journey into numerology began
              with exploring countless videos and calculating numerological
              details for himself and his family, searching for meaningful
              insights and remedies. Now, he has consolidated his extensive
              knowledge and tools into Elite Numerology, making these resources
              with Authentic knowledge available to everyone in a single,
              unified location.
            </p>
          </div>
          <div className="section" id="image2">
            <img className="about-us-img" src={logo1} alt="Image 2" />
          </div>
        </div>
        <div className="column">
          <div className="section" id="image3">
            <img className="about-us-img" src={logo2} alt="Image 3" />
          </div>
          <div className="section" id="services">
            <h2>Our Services</h2>
            <p>
              We offer a diverse range of numerology tools designed to provide
              personalized predictions and remedies tailored to your unique
              numerical vibrations. Whether you're a beginner curious about the
              basics of numerology or a seasoned practitioner, our platform
              accommodates all levels of interest and expertise. Our offerings
              include:
              <ul>
                <li>
                  Detailed numerology predictions personalized to your numbers.
                </li>
                <li>
                  A vast array of both Western and traditional numerology tools.
                </li>
                <li>
                  A unique Fasting Calculator that determines the best days for
                  fasting based on your numerology numbers.
                </li>
              </ul>
            </p>
          </div>
          <div className="section" id="image4">
            <img className="about-us-img" src={logo2} alt="Image 4" />
          </div>
          <div className="section" id="unique">
            <h2>Elite Numerology</h2>
            <p>
              This more than just a numerology site, it's a comprehensive
              toolkit built on meticulously gathered data to offer you the best
              possible user experience. Our features/Services include:
              <ul>
                <li>
                  Instantaneous calculations, allowing other numerologists to
                  leverage our tools for their use.
                </li>
                <li>
                  API access for developing your own white-label numerology
                  tools.
                </li>
                <li>
                  A knowledge hub or Learning centre for those eager to delve
                  deeper into numerology principles and apply practical
                  techniques.{" "}
                </li>
                <li>AI based Name Change Suggestions </li>
                <li>
                  Free Interpretation of Each Number using tools available on
                  Website
                </li>
                <li>Balanced Logo Design Service and Many More </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <h1 className="about-us-h1">Vision and Mission</h1>
      <p className="vision-mission-p">
        Our mission is simple: to reach a vast audience who believe in the power
        of numerology and wish to explore it further. We welcome collaboration
        with other numerologists, offering a platform to list their credentials
        and connect with potential clients.
      </p>
    </div>
  );
};

export default AboutUs;
