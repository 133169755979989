import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom"; // Import Link from React
import logo1 from "../assets/logo1.png";

function Header({ onEditClick }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNumerologyOpen, setIsNumerologyOpen] = useState(false);
  const [isCompatibilityOpen, setIsCompatibilityOpen] = useState(false);
  const hoverTimeout = useRef(null);
  const location = useLocation();
  const isFooterPage =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/contact-us" ||
    location.pathname === "/terms-and-conditions" ||
    location.pathname === "/faq";

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Event listener to close dropdowns when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsNumerologyOpen(false);
        setIsCompatibilityOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleNumerologyDropdown = (event) => {
    event.stopPropagation();
    setIsNumerologyOpen(!isNumerologyOpen);
    setIsCompatibilityOpen(false);
  };

  const toggleCompatibilityDropdown = (event) => {
    event.stopPropagation();
    setIsCompatibilityOpen(!isCompatibilityOpen);
    setIsNumerologyOpen(false);
  };

  const closeDropdownMenus = () => {
    setIsNumerologyOpen(false);
    setIsCompatibilityOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleMouseEnter = (menuSetter, otherMenuSetter) => {
    clearTimeout(hoverTimeout.current);
    menuSetter(true);
    otherMenuSetter(false);
  };

  const handleMouseLeave = (menuSetter) => {
    hoverTimeout.current = setTimeout(() => {
      menuSetter(false);
    }, 1000); // 1 second delay
  };

  return (
    <header className="header">
      <div className={`header-content ${isMobileMenuOpen ? "open" : ""}`}>
        <div className="header-logo-and-h1">
          <Link to="/" className="header-nav-logo">
            <img className="header-logo-image" src={logo1} alt="Logo" />
          </Link>
        </div>

        <nav className={`header-nav-center ${isMobileMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/" onClick={closeDropdownMenus}>
                Home
              </Link>
            </li>
            <li
              className={`dropdown ${isNumerologyOpen ? "open" : ""}`}
              onMouseEnter={() =>
                handleMouseEnter(setIsNumerologyOpen, setIsCompatibilityOpen)
              }
              onMouseLeave={() => handleMouseLeave(setIsNumerologyOpen)}
            >
              <a href="#" onClick={toggleNumerologyDropdown}>
                Numerology
              </a>
              <div className="dropdown-content">
                {/* List of Links */}
                <Link
                  to="/balance-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Balance Number
                </Link>
                <Link
                  to="/is-your-bank-account-number-lucky"
                  onClick={closeDropdownMenus}
                >
                  Bank A/C Number
                </Link>

                <Link
                  to="/personal-fast-day-calculator-numerology"
                  onClick={closeDropdownMenus}
                >
                  Best Day to Fast
                </Link>
                <Link
                  to="/lucky-business-name-calculator"
                  onClick={closeDropdownMenus}
                >
                  Business Name
                </Link>
                <Link
                  to="/daily-numerological-chart"
                  onClick={closeDropdownMenus}
                >
                  Daily Biorhythm Chart
                </Link>
                <Link
                  to="/destiny-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Destiny Number
                </Link>
                <Link
                  to="/hidden-passion-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Hidden Passion Number
                </Link>
                <Link
                  to="/is-your-house-number-lucky-with-remedies"
                  onClick={closeDropdownMenus}
                >
                  House Number
                </Link>
                <Link
                  to="/jersey-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Jersey Number
                </Link>
                <Link
                  to="/what-are-karmic-debt-numbers-in-numerology"
                  onClick={closeDropdownMenus}
                >
                  Karmic Debt Number
                </Link>
                <Link to="/kua-number-calculator" onClick={closeDropdownMenus}>
                  Kua Number
                </Link>
                <Link
                  to="/life-path-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Life Path Number
                </Link>
                <Link
                  to="/create-your-lo-shu-grid-missing-number-remedies"
                  onClick={closeDropdownMenus}
                >
                  Lo Shu Grid
                </Link>

                <Link
                  to="/numerology-maturity-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Maturity Number
                </Link>
                <Link
                  to="/is-your-mobile-number-lucky"
                  onClick={closeDropdownMenus}
                >
                  Mobile Number
                </Link>
                <Link
                  to="/personalized-monthly-biorhythm-chart"
                  onClick={closeDropdownMenus}
                >
                  Monthly Biorhythm Chart
                </Link>
                <Link to="/name-number-calculator" onClick={closeDropdownMenus}>
                  Name Number
                </Link>
                <Link
                  to="/numerology-personal-year-calculator"
                  onClick={closeDropdownMenus}
                >
                  Personal Year Number
                </Link>
                <Link
                  to="/personality-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Personality Number
                </Link>
                <Link
                  to="/pinnacle-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Pinnacle Cycle
                </Link>
                <Link
                  to="/soul-urge-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Soul Urge Number
                </Link>
                <Link
                  to="/subconscious-self-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Subconscious Self Number
                </Link>
                <Link
                  to="/is-your-vehicle-number-lucky-with-remedies"
                  onClick={closeDropdownMenus}
                >
                  Vehicle Number
                </Link>
              </div>
            </li>
            <li
              className={`dropdown ${isCompatibilityOpen ? "open" : ""}`}
              onMouseEnter={() =>
                handleMouseEnter(setIsCompatibilityOpen, setIsNumerologyOpen)
              }
              onMouseLeave={() => handleMouseLeave(setIsCompatibilityOpen)}
            >
              <a href="#" onClick={toggleCompatibilityDropdown}>
                Compatibility
              </a>
              <div className="dropdown-content">
                <Link
                  to="/numerology-partner-compatibility"
                  onClick={closeDropdownMenus}
                >
                  Partner Compatibility
                </Link>
                <Link
                  to="/numerology-kua-compatibility"
                  onClick={closeDropdownMenus}
                >
                  Kua Number Compatibility
                </Link>
              </div>
            </li>
          </ul>
        </nav>

        <nav className={`header-nav-right ${isMobileMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=9910335637&text=I%20want%20to%20book%20a%20numerology%20consultation"
                className="chat-now"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat Now
              </a>
            </li>
            <li>
              <button
                onClick={onEditClick}
                className={`edit-button ${isFooterPage ? "disabled" : ""}`}
                disabled={isFooterPage}
              >
                Edit User Data
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
