const exampleTextsPersonalYear = {
  step1: "Example: 10-10-1989",
  step2: "Write down your date of birth in DD-MM format: Date of birth: 10-10",
  step3:
    "Add all digits together, including the current year in full (2024): 1 + 0 + 1 + 0 + 2 + 0 + 2 + 4 = 10",
  step4:
    "If the sum is greater than 9, sum both digits again until the sum is a single digit: 1 + 0 = 1",
  step5:
    "Check if the result is a Master Number: The result is 1, which is not a Master Number.",
  step6: "Personal Year Number: The Personal Year Number is 1.",
};

export default exampleTextsPersonalYear;
