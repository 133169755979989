// src/components/RateLimit.js
import React from "react";
import { useLocation } from "react-router-dom";

export function RateLimit({ rateLimitCount, timer }) {
  const location = useLocation();
  const showRateLimit = ![
    "/",
    "/contact-us",
    "/about-us",
    "/terms-and-conditions",
    "/faq",
  ].includes(location.pathname);

  return showRateLimit ? (
    <div>
      <p>
        Rate Limit: {rateLimitCount} Timer: {timer}
      </p>
    </div>
  ) : null;
}
