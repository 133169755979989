import React, { useEffect } from "react";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import {
  termsHeading,
  termsIntroductionHeading,
  termsUseOfWebsiteHeading,
  termsEligibilitySubheading,
  termsAccountResponsibilitySubheading,
  termsProhibitedActivitiesSubheading,
  termsServicesProvidedHeading,
  termsDescriptionOfServicesSubheading,
  termsAccuracyOfInformationSubheading,
  termsIntellectualPropertyHeading,
  termsContentOwnershipSubheading,
  termsUserGeneratedContentSubheading,
  termsPrivacyPolicyHeading,
  termsDataCollectionSubheading,
  termsCookiesSubheading,
  termsPaymentAndRefundsHeading,
  termsPaymentTermsSubheading,
  termsRefundPolicySubheading,
  termsDisclaimersAndLiabilityHeading,
  termsNoProfessionalAdviceSubheading,
  termsLimitationOfLiabilitySubheading,
  termsUserConductHeading,
  termsUserObligationsSubheading,
  termsTerminationOfAccessSubheading,
  termsChangesToTermsHeading,
  termsModificationOfTermsSubheading,
  termsGoverningLawHeading,
  termsJurisdictionSubheading,
  termsContactInformationHeading,
  termsCustomerSupportSubheading,
  termsIntroduction,
  termsUseEligibility,
  termsAccountResponsibility,
  termsProhibitedActivities,
  termsDescriptionOfServices,
  termsAccuracyOfInformation,
  termsContentOwnership,
  termsUserGeneratedContent,
  termsDataCollection,
  termsCookies,
  termsPaymentTerms,
  termsRefundPolicy,
  termsNoProfessionalAdvice,
  termsLimitationOfLiability,
  termsUserObligations,
  termsTerminationOfAccess,
  termsModificationOfTerms,
  termsJurisdiction,
  termsCustomerSupport,
} from "../components/Constants";

const mouseTitle = "Terms & Conditions";
const browserTitle = "Terms & Conditions";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  return (
    <div className="tandc">
      <Helmet>
        <title>Elite Numerology - Terms and Conditions</title>
      </Helmet>
      <h1 className="tandc-h1">{termsHeading}</h1>
      <h2>{termsIntroductionHeading}</h2>
      <hr></hr>
      <p>{termsIntroduction}</p>
      <h2>{termsUseOfWebsiteHeading}</h2>
      <hr></hr>
      <h3>{termsEligibilitySubheading}</h3>
      <p>{termsUseEligibility}</p>
      <h3>{termsAccountResponsibilitySubheading}</h3>
      <p>{termsAccountResponsibility}</p>
      <h3>{termsProhibitedActivitiesSubheading}</h3>
      <p>{termsProhibitedActivities}</p>
      <h2>{termsServicesProvidedHeading}</h2>
      <hr></hr>
      <h3>{termsDescriptionOfServicesSubheading}</h3>
      <p>{termsDescriptionOfServices}</p>
      <h3>{termsAccuracyOfInformationSubheading}</h3>
      <p>{termsAccuracyOfInformation}</p>
      <h2>{termsIntellectualPropertyHeading}</h2>
      <hr></hr>
      <h3>{termsContentOwnershipSubheading}</h3>
      <p>{termsContentOwnership}</p>
      <h3>{termsUserGeneratedContentSubheading}</h3>
      <p>{termsUserGeneratedContent}</p>
      <h2>{termsPrivacyPolicyHeading}</h2>
      <hr></hr>
      <h3>{termsDataCollectionSubheading}</h3>
      <p>{termsDataCollection}</p>
      <h3>{termsCookiesSubheading}</h3>
      <p>{termsCookies}</p>
      <h2>{termsPaymentAndRefundsHeading}</h2>
      <hr></hr>
      <h3>{termsPaymentTermsSubheading}</h3>
      <p>{termsPaymentTerms}</p>
      <h3>{termsRefundPolicySubheading}</h3>
      <p>{termsRefundPolicy}</p>
      <h2>{termsDisclaimersAndLiabilityHeading}</h2>
      <hr></hr>
      <h3>{termsNoProfessionalAdviceSubheading}</h3>
      <p>{termsNoProfessionalAdvice}</p>
      <h3>{termsLimitationOfLiabilitySubheading}</h3>
      <p>{termsLimitationOfLiability}</p>
      <h2>{termsUserConductHeading}</h2>
      <hr></hr>
      <h3>{termsUserObligationsSubheading}</h3>
      <p>{termsUserObligations}</p>
      <h3>{termsTerminationOfAccessSubheading}</h3>
      <p>{termsTerminationOfAccess}</p>
      <h2>{termsChangesToTermsHeading}</h2>
      <hr></hr>
      <h3>{termsModificationOfTermsSubheading}</h3>
      <p>{termsModificationOfTerms}</p>
      <h2>{termsGoverningLawHeading}</h2>
      <hr></hr>
      <h3>{termsJurisdictionSubheading}</h3>
      <p>{termsJurisdiction}</p>
      <h2>{termsContactInformationHeading}</h2>
      <hr></hr>
      <h3>{termsCustomerSupportSubheading}</h3>
      <p>{termsCustomerSupport}</p>
      <MouseFollower mouseTitle={mouseTitle} />
    </div>
  );
};

export default TermsAndConditions;
