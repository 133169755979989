const exampleTextsVehicle = {
  step1:
    "Write Down Your Vehicle Number and Date of Birth: Example: Vehicle Number: 7781, Date of Birth: 07-07-1981",
  step2:
    "Find Your Driver Number: Calculate the Driver Number by summing the digits of the day part of your Date of Birth: 07 → 0 + 7 = 7",
  step3:
    "Calculate Your Vehicle Number: Sum all the digits of your Vehicle Number together: 7781 → 7 + 7 + 8 + 1 = 23 → 2 + 3 = 5",
  step4:
    "Pass These Numbers to Elite Numerology Algorithm: Use the Elite Numerology Algorithm to calculate a New Number based on your Driver Number (7) and Vehicle Number (5): Suppose the New Number calculated is 6.",
  step5:
    "Find Compatibility Using Elite Numerology Chart: Relay the New Number (6) using the Elite Numerology Chart to determine if this number is compatible with you or not.",
  step6:
    "Interpret the Result: Based on the Elite Numerology Chart, check the compatibility and interpret the result to understand how it aligns with your profile.",
};

export default exampleTextsVehicle;
