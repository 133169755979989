import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import { Helmet } from "react-helmet";
import { GlobalStateContext } from "./components/GlobalVariable";
import Header2 from "./components/Header2";
import { RateLimit } from "./components/RateLimit"; // Correct import
import Footer from "./components/Footer";
import Breadcrumbs from "./components/Breadcrumbs"; // Import Breadcrumbs component
import "./App.css";
import MouseFollower from "./components/MouseFollower";
import PopupForm from "./components/PopupForm";
import Home from "./pages/Home";
import HomeMobile from "./pages/HomeMobile";
import BestDayToFast from "./pages/BestDayToFast";
import KuaNumber from "./pages/KuaNumber";
import PersonalYear from "./pages/PersonalYear";
import LoShuGrid from "./pages/LoShuGrid";
import LifePath from "./pages/LifePath";
import LuckyVehicle from "./pages/LuckyVehicle";
import NameNumber from "./pages/NameNumber";
import Partner from "./pages/Partner";
import Faq from "./pages/FAQ";
import BiorhythmChart from "./pages/BiorhythmChart";
import MobileNumber from "./pages/MobileNumber";
import HouseNumber from "./pages/HouseNumber";
import BankNumber from "./pages/BankNumber";
import BusinessName from "./pages/BusinessName";
import SoulUrge from "./pages/SoulUrge";
import Maturity from "./pages/Maturity";
import Pinnacle from "./pages/Pinnacle";
import Balance from "./pages/Balance";
import Passion from "./pages/Passion";
import SubconsciousSelf from "./pages/SubconsciousSelf";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Karmic from "./pages/Karmic";
import DailyBiorhythmChart from "./pages/DailyBiorhythm";
import Destiny from "./pages/Destiny";
import Personality from "./pages/Personality";
import Jersey from "./pages/Jersey";
import TermsAndConditions from "./pages/TermsAndConditions";
import KuaCompatibility from "./pages/KuaCompatibility";
import { GlobalStateProvider } from "./components/GlobalVariable";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  ReactGA.initialize("G-6XCK70FT54"); // Replace with your Tracking ID
  const [disableFeatures, setDisableFeatures] = useState(false);
  const { rateLimitCount, setRateLimitCount, timer } =
    useContext(GlobalStateContext);
  const [popupVisible, setPopupVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    day: 23,
    month: 10,
    year: 1990,
    fullName: "",
  });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1250);
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.innerWidth <= 576
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      setUserData(JSON.parse(storedData));
    } else {
      setPopupVisible(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1250);
      setIsMobileScreen(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePopupClose = (updatedUserData) => {
    setPopupVisible(false);
    setIsEditing(false);
    if (updatedUserData) {
      setUserData(updatedUserData);
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }
  };

  const handleEditClick = () => {
    console.log("Edit button clicked");
    setPopupVisible(true);
    setIsEditing(true);
  };

  useEffect(() => {
    const disableRightClick = (e) => {
      if (disableFeatures && e.button === 2) {
        e.preventDefault();
        return false;
      }
    };

    const disableF12 = (e) => {
      if (
        disableFeatures &&
        (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && e.keyCode === 73))
      ) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableF12);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableF12);
    };
  }, [disableFeatures]);

  return (
    <Router>
      <ScrollToTop />
      <div>
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Elite Numerology",
              "description": "Elite Numerology : Numerology Tools , Consulations , Learning and many More",
              "url": "https://elitenumerology.com",
              "publisher": {
                "@type": "Elite Numerology",
                "name": "Elite Numerology",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://elitenumerology.com/logo512.png"
                }
              }
            }
          `}
          </script>
        </Helmet>
        <div className="page-container">
          {isSmallScreen ? (
            <Header2 onEditClick={handleEditClick} />
          ) : (
            <Header onEditClick={handleEditClick} />
          )}
          <div
            className="left-align-content"
            id="breadcrumb-and-rate-container"
          >
            <div id="breadcrumbs1">
              <Breadcrumbs userData={userData} />
            </div>
            <RateLimit rateLimitCount={rateLimitCount} timer={timer} />
          </div>

          <Routes>
            {isMobileScreen ? (
              <Route path="/" element={<HomeMobile />} />
            ) : (
              <Route
                path="/"
                element={<Home userData={userData} setUserData={setUserData} />}
              />
            )}
            <Route
              path="/personal-fast-day-calculator-numerology"
              element={
                <BestDayToFast userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/balance-number-calculator"
              element={
                <Balance userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/kua-number-calculator"
              element={
                <KuaNumber userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/jersey-number-calculator"
              element={<Jersey userData={userData} setUserData={setUserData} />}
            />
            <Route
              path="/numerology-personal-year-calculator"
              element={
                <PersonalYear userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <TermsAndConditions
                  userData={userData}
                  setUserData={setUserData}
                />
              }
            />
            <Route
              path="/create-your-lo-shu-grid-missing-number-remedies"
              element={
                <LoShuGrid userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/life-path-number-calculator"
              element={
                <LifePath userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/is-your-vehicle-number-lucky-with-remedies"
              element={
                <LuckyVehicle userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/destiny-number-calculator"
              element={
                <Destiny userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/numerology-partner-compatibility"
              element={
                <Partner userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/numerology-kua-compatibility"
              element={
                <KuaCompatibility
                  userData={userData}
                  setUserData={setUserData}
                />
              }
            />
            <Route path="/faq" element={<Faq />} />
            <Route
              path="/personalized-monthly-biorhythm-chart"
              element={
                <BiorhythmChart userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/daily-numerological-chart"
              element={
                <DailyBiorhythmChart
                  userData={userData}
                  setUserData={setUserData}
                />
              }
            />
            <Route
              path="/is-your-mobile-number-lucky"
              element={
                <MobileNumber userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/is-your-house-number-lucky-with-remedies"
              element={
                <HouseNumber userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/personality-number-calculator"
              element={
                <Personality userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/is-your-bank-account-number-lucky"
              element={
                <BankNumber userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/lucky-business-name-calculator"
              element={
                <BusinessName userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/soul-urge-number-calculator"
              element={
                <SoulUrge userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/numerology-maturity-number-calculator"
              element={
                <Maturity userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/pinnacle-number-calculator"
              element={
                <Pinnacle userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/name-number-calculator"
              element={
                <NameNumber userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/hidden-passion-number-calculator"
              element={
                <Passion userData={userData} setUserData={setUserData} />
              }
            />
            <Route
              path="/subconscious-self-number-calculator"
              element={
                <SubconsciousSelf
                  userData={userData}
                  setUserData={setUserData}
                />
              }
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route
              path="/what-are-karmic-debt-numbers-in-numerology"
              element={<Karmic />}
            />
          </Routes>
        </div>
        <div id="popup1">
          <PopupForm
            isVisible={popupVisible}
            onClose={handlePopupClose}
            userData={userData}
            setUserData={setUserData}
            isEditing={isEditing}
          />
        </div>
        <div id="popup2">
          <MouseFollower />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
