export const nameNumberInstructions = {
  howToCalculate: `
    <p>Write down your full name.</p>

    <p>Refer to the Chandelean Numerology Chart to find the number of each consonant in your name.</p>

    <p>Assign digits to each consonant.</p>

    <p>Add all the assigned digits for the consonants together to get the total sum value.</p>

    <p>Reduce the total sum to a single digit unless it is a master number (11, 22, or 33).</p>

    <p>The resulting single digit or master number is your Name Number.</p>
  `,
};
