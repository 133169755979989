const exampleTextsKuaNumber = {
  step1: "Example 1: Male, 10-10-1989",
  step2:
    "Sum and reduce the last two digits of the birth year to a single digit: Last two digits of 1989: 8 + 9 = 17. Reduce 17 to a single digit: 1 + 7 = 8",
  step3:
    "If born before 2000, subtract this single digit from 10: 10 - 8 = 2. Kua number: The result is 2.",

  step4: "Example 2: Female, 10-10-1989",
  step5:
    "Sum and reduce the last two digits of the birth year to a single digit: Last two digits of 1989: 8 + 9 = 17. Reduce 17 to a single digit: 1 + 7 = 8",
  step6:
    "Add 5 to this single digit and reduce again to a single digit: 8 + 5 = 13. Reduce 13 to a single digit: 1 + 3 = 4. Kua number: The result is 4.",
};

export default exampleTextsKuaNumber;
