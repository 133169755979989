export const fastingDayInstructions = {
  howToCalculate: `
    <p><b>How We Calculate Fasting Day</b><br /><br>
    Write Down Your Date of Birth in DD-MM-YYYY format.</p>

    <p><b>Calculate Driver Number:</b> Sum of DD from your Date of Birth</p>

    <p><b>Calculate the Conductor Number:</b> Sum of all the digits of your date of birth together until you get a single digit.</p>

    <p>Now Based on your Driver and Conductor Number, find the Planet information from the chart Below.</p>

    <p>Now From our Algo We Find the Harmonizing Planet of both Driver number Planet and Conductor Number Planet,
    once we Deduce the planet Information we Map the same with the Day using the corresponding day names.</p>
  `,
};
