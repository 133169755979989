// src/constants/exampleTextsPersonalityNumber.js
const exampleTextsPersonalityNumber = {
  step1: "Write Down Your Full Name: Ashish Batra.",
  step2: "Identify the Consonants in your name: S, H, S, H,  B, T, R.",
  step3:
    "Refer to the Chaldean Numerology Chart to find the number of each consonant in your name.",
  step4:
    "Assign Digits to Each Consonant: S = 3, H = 5, S = 3, H = 5, B = 2, T = 4, R = 2.",
  step5:
    "Add All the Assigned Digits Together: 3 + 5 + 3 + 5 + 2 + 4 + 2 = 24.",
  step6:
    "Reduce the Total Sum to a Single Digit: 24 → 2 + 4 = 6. The resulting number is your Personality Number: 6.",
};

export default exampleTextsPersonalityNumber;
