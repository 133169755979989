const exampleTextsBalance = {
  step1: "Write Down Your Full Name: Ashish Batra.",
  step2: "Take the Initials: A (Ashish), B (Batra)",
  step3: "Assign Corresponding Digits to Initials: A = 1, B = 2",
  step4: "Add the Assigned Digits for the Initials: A (1) + B (2) = 2.",
  step5: "Reduce the Total Sum to a Single Digit: 3 (already a single digit).",
  step6: "Calculate Your Balance Number: The resulting single digit is 3.",
};

export default exampleTextsBalance;
