const exampleTextsKarmicDebt = {
  step1:
    "Write Down Your Date of Birth and Full Name: Date of Birth: 10-10-1989, Full Name: Ashish Batra.",
  step2:
    "Use the Pythagorean Numerology Chart to find corresponding digits for each letter in your full name.",
  step3:
    "Assign Corresponding Digits: A = 1, S = 1, H = 8, I = 9, S = 1, H = 8, B = 2, A = 1, T = 2, R = 9, A = 1.",
  step4:
    "Add All Assigned Digits Together: 1 + 1 + 8 + 9 + 1 + 8 + 2 + 1 + 2 + 9 + 1 = 52.",
  step5: "Reduce the Total Sum to a Single Digit: 52 → 5 + 2 = 7.",
  step6:
    "Check for Karmic Debt Numbers: The resulting digit 7 and the components of the date of birth do not match 13, 14, 16, or 19. Therefore, there is no Karmic Debt.",
};

export default exampleTextsKarmicDebt;
